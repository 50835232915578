import styled from "styled-components/macro";
import { useState, useEffect, useRef, useMemo } from "react";
import Modal from "../TheGame/Modal";
import Button from "../TheGame/Button";
import InputIcon from "../InputIcon";
import { btnColors, btnIcons } from "../shared-styles";
import ModalChild from "../TheGame/ModalChild";
import { RenameItemButtonProps } from "./RenameItemButton";
import { ref, getDatabase, get } from "firebase/database";
import { adminPlurals } from "./adminUtils";
import { useNavigate } from "react-router-dom";

const db = getDatabase();

interface RenameItemModalProps extends RenameItemButtonProps {
  cancel: () => void;
}

function RenameItemModal(props: RenameItemModalProps) {
  const navigate = useNavigate();
  const { itemType, cancel, submit, existingId } = props;
  const [existingIds, setExistingIds] = useState<string[] | null>(null);
  const [id, setId] = useState(existingId);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const hasBannedChars = useMemo(() => {
    return id.match(/[.#$[\]]/);
  }, [id]);

  const canSubmit =
    !hasBannedChars &&
    !isSubmitting &&
    existingIds &&
    !existingIds.includes(id);

  const inputEl = useRef<HTMLInputElement | null>(null);
  useEffect(() => {
    inputEl.current!.value = existingId;
  }, [existingId]);

  useEffect(() => {
    const dbRef = ref(db, `${adminPlurals[itemType]!}`);
    get(dbRef).then((snapshot) => {
      const val = Object.keys(snapshot.val());
      setExistingIds(val);
    });
  }, [existingId, itemType]);

  async function onSubmit() {
    setIsSubmitting(true);
    await submit(id);
    navigate(`./../${id}`, { replace: true });
    cancel();
  }

  return (
    <StyledRenameItemModal>
      <Modal dismiss={isSubmitting ? undefined : cancel}>
        <h1>Rename {itemType}</h1>
        <div className="spacer"></div>
        <ModalChild>
          <InputIcon icon={btnIcons[itemType]}>
            <input
              ref={inputEl}
              autoFocus
              className="id-input"
              type="text"
              title="id input"
              placeholder={`enter ${itemType} name`}
              onChange={({ target }) => {
                setId(target.value);
              }}
            />
          </InputIcon>
        </ModalChild>
        <div className="spacer"></div>
        <Button
          width={200}
          buttonText="Rename"
          color={btnColors.rename}
          className="item-rename"
          disabled={!canSubmit}
          onClick={() => onSubmit()}
        ></Button>
        <div className="warning rename-warning">
          Renaming will lose any unsaved edits.
        </div>
        <div
          className={`warning banned-characters ${
            hasBannedChars ? "show" : ""
          }`}
        >
          Name must not contain ".", "#", "$", "[", or "]"
        </div>
        {
          <button
            className={`cancel ${isSubmitting ? "hide" : ""}`}
            onClick={cancel}
          >
            Cancel
          </button>
        }
      </Modal>
    </StyledRenameItemModal>
  );
}

export default RenameItemModal;

const StyledRenameItemModal = styled.div`
  .item-rename {
    margin-top: 30px;
  }

  .warning {
    text-transform: none;
    font-size: 0.6rem;
    margin-top: 5px;
  }

  .banned-characters {
    color: red;
    visibility: hidden;

    &.show {
      visibility: initial;
    }
  }

  button.cancel {
    margin-top: 20px;

    &.hide {
      visibility: hidden;
    }
  }

  .spacer {
    flex: 1;
  }
`;
