import styled from "styled-components/macro";
import { useParams } from "react-router-dom";
import { MediumButton } from "./TheGame/Button";
import { useEffect, useState } from "react";
import { getDatabase, ref, get } from "firebase/database";
import AppHeader from "./AppHeader";
import { btnIcons, btnColors } from "./shared-styles";
import HomeHeader from "./HomeHeader";
import LaunchPanel from "./LaunchPanel";
import ReportIcon from "@mui/icons-material/Report";
import SlideDown from "./SlideDown";
import starryBackground from "./TheGame/assets/imgs/starry_background.svg";

const db = getDatabase();

const HomeFolder = () => {
  const { classId, folderId } = useParams();

  const [sets, setSets] = useState<string[] | null>(null);
  const [selectedSet, setSelectedSet] = useState<string | undefined>(undefined);
  const [hasLaunched, setHasLaunched] = useState(false);

  useEffect(() => {
    const dbRef = ref(db, `folderSets/${folderId}`);
    get(dbRef).then((snapshot) => {
      const val = snapshot.val();
      setSets(Object.keys(val ?? {}));
    });
  }, [folderId]);

  const setBtn = (setId: string, i: number) => (
    <SlideDown key={i} delay={Math.random() * 0.15} duration={0.25}>
      <MediumButton
        className="set-btn"
        key={i}
        onClick={() => setSelectedSet((s) => (s === setId ? undefined : setId))}
        buttonText={setId}
        icon={btnIcons.set}
        color={btnColors.set}
        selected={setId === selectedSet}
      ></MediumButton>
    </SlideDown>
  );

  return (
    <StyledHomeFolder>
      <AppHeader>
        <div className="home-folder">
          <HomeHeader
            backTo={`/home/class/${classId}`}
            isReady={!!selectedSet}
            hasLaunched={hasLaunched}
          />
          <div className="home-folder-children">
            {!!sets?.length && sets.map((set, i) => setBtn(set, i))}
            {sets?.length === 0 && (
              <div className="no-sets">
                <ReportIcon />
                <span>NO SETS</span>
              </div>
            )}
          </div>
          <LaunchPanel
            readyTypes={["class", "folder", ...(selectedSet ? ["set"] : [])]}
            handleLaunch={() => {
              setHasLaunched(true);
              return new Promise((resolve) => setTimeout(resolve, 500));
            }}
            setId={selectedSet}
          />
        </div>
      </AppHeader>
    </StyledHomeFolder>
  );
};

export default HomeFolder;

const StyledHomeFolder = styled.div`
  background-image: url(${starryBackground});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  color: white;

  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;

  .home-folder {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-bottom: 100px;

    .home-folder-children {
      justify-content: center;
      margin-bottom: 100px;
      display: flex;
      flex-wrap: wrap;
      .button-container {
        margin-bottom: 20px;
      }
    }
  }

  .no-sets {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;
