import { useState } from "react";
import { btnColors } from "../shared-styles";
import { SmallButton } from "../TheGame/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteItemModal from "./DeleteItemModal";

export interface DeleteItemButtonProps {
  id: string;
  submit: () => Promise<void>;
  itemType: string;
}

function DeleteItemButton(props: DeleteItemButtonProps) {
  const [isDeleting, setIsDeleting] = useState(false);
  return (
    <>
      <SmallButton
        color={btnColors.delete}
        buttonText="Delete"
        onClick={() => {
          setIsDeleting(true);
        }}
        icon={DeleteIcon}
      />
      {isDeleting && (
        <DeleteItemModal
          {...props}
          cancel={() => {
            setIsDeleting(false);
          }}
        />
      )}
    </>
  );
}

export default DeleteItemButton;
