import { useEffect, useRef } from "react";
import styled from "styled-components/macro";
import { colors } from "../shared-styles";
interface IProps {
  children: React.ReactNode;
  dismiss?: VoidFunction;
}

const Modal = ({ children, dismiss }: IProps) => {
  const overlayEl = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    if (dismiss) {
      overlayEl.current!.addEventListener("click", ({ target }) => {
        if (target === overlayEl.current) dismiss();
      });
    }
  }, [dismiss]);
  return (
    <StyledModal>
      <div ref={overlayEl} className="modal-overlay">
        <div className="modal">{children}</div>
      </div>
    </StyledModal>
  );
};

export default Modal;

const {
  mainTextColor,
  modalBackgroundColor,
  modalOverlayColor,
  modalChildBackgroundColor,
} = colors;
const StyledModal = styled.div`
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  .modal-overlay {
    background-color: ${modalOverlayColor};
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .modal {
    color: ${mainTextColor};
    background-color: ${modalBackgroundColor};
    border-radius: 20px;
    width: clamp(300px, 600px, 700px);
    max-width: calc(100% - 20px);
    min-height: 400px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .modal-child {
    background: ${modalChildBackgroundColor};
    border-radius: 10px;
    padding: 20px;
  }
`;
