import styled from "styled-components/macro";
import SlideDown from "./SlideDown";
import Button from "./TheGame/Button";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import MissionClouds from "./MissionClouds";
import MissionLandBlobs from "./MissionLandBlobs";
import { useState } from "react";

interface Props {
  name: string | React.ReactNode,
  onClick: VoidFunction,
  color: string,
  index: number,
  hoverCallback: (color: string | null) => void,
}

const MissionButton = (props: Props) => {
  const { name, onClick, color, index, hoverCallback } = props;

  const [seed] = useState(Math.random());

  const size = 150;
  return (
    <StyledMissionButton {...props}>
      <SlideDown className="mission-button-outer" key={index} delay={Math.random() * 0.15} duration={0.25}>
        <div className="pulse"></div>
        <StyledSatellite color={color} seed={seed}>
          <RocketLaunchIcon className="satellite" />
        </StyledSatellite>
        <div className="mission-button-container" onClick={(ev) => { ev.stopPropagation() }}>
          <Button
            height={size}
            className="mission-button"
            onClick={onClick}
            onMouseEnter={() => hoverCallback(color)}
            onMouseLeave={() => hoverCallback(null)}
            buttonText={name}
            width={size}
            color={color}
            borderRadius={70}
          >
            {MissionLandBlobs({ color, key: index })}
            {MissionClouds({ color, key: index })}
            <div className="planet-shadow"></div>
          </Button>
        </div>
      </SlideDown>
    </StyledMissionButton>
  );
};

export default MissionButton;

export const StyledMissionButton = styled.div<Props>`
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      .mission-button-container {
        display: flex;
        flex-direction: row;
        padding: 30px;
      }

      .mission-button {
        .btn-children {
          .clouds{
            filter: saturate(5) brightness(5);
          }
          .land-blobs {
            filter: saturate(5) brightness(0.90);
          }
          .planet-shadow {
            position: absolute;
            top: 0;
            right: 0;
            width: 50%; /* Occupies the right 50% of the container */
            height: 100%; /* Occupies the full height of the container */
            background-color: rgb(0,0,0,0.1); /* Example background color */
            pointer-events: none;
          }
        }
          .btn-text {
          font-size: 1.3rem;
          color: rgb(40,0,80);
          // font-weight: normal;
          background: ${p => p.color}B0;
          border-radius: 10px;
          padding: 2px 5px;
        }
      }

      .mission-button-outer {
        --satellite-scale: 0;
      }
      .mission-button-outer:has(.mission-button:hover) {
        --satellite-scale: 1;
        :root {
          --guy-color: ${p => p.color};
        }

        .pulse {
          display: block;
        }
      }

      .pulse {
        background-color: ${(p) => p.color};
        display: none;
        width: 100%;
        height: 100%;
        position: absolute;
        border-radius: 45%;
        top: 0;
        transform: scale(0.55);
        opacity: 1;
        filter: brightness(0.6);
        @keyframes pulse {
          100% {
            opacity: 0;
            transform: scale(0.9);
          }
        }
        animation: pulse 0.8s ease-out;
      }
`;

interface SatProps {
  color: string,
  seed: number,
}

export const StyledSatellite = styled.div.attrs<SatProps>(({ color, seed }) => ({
  style: {
    animation: `orbit 3.5s infinite linear ${seed > 0.5 ? 'reverse' : ''}`,
    animationDelay: `-${seed * 3.5}s`,
    color: color,
  },
})) <SatProps>`
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;

  @keyframes orbit {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .satellite {
    opacity: var(--satellite-scale);
    transform: scale(var(--satellite-scale)) rotate(${({ seed }) => seed > 0.5 ? 225 : 45}deg);
    transition: opacity 0.15s, transform 0.5s;
    filter: saturate(3);
}

`;