import styled from "styled-components/macro";
import SvgIcon from "@mui/material/SvgIcon";

interface Props {
  onClick: VoidFunction;
  onMouseEnter?: VoidFunction;
  onMouseLeave?: VoidFunction;
  buttonText: string | React.ReactNode;
  selected?: boolean;
  disabled?: boolean;
  className?: string;
  color?: string;
  height?: number;
  width?: number;
  depth?: number;
  borderRadius?: number;
  icon?: typeof SvgIcon;
  backgroundImage?: typeof Image;
  children?: React.ReactNode;
}

export function MediumButton(props: Props) {
  const height = props.height ?? 35;
  const depth = props.depth ?? 5;
  return (
    <StyledMediumButton>
      <Button
        {...{
          ...props,
          height,
          depth,
          className: `btn-medium-small ${props.className ?? ""}`,
        }}
      />
    </StyledMediumButton>
  );
}

const StyledMediumButton = styled.div`
  .button-top,
  .button-top > svg {
    font-size: 1rem;
  }
`;

export function SmallButton(props: Props) {
  const height = props.height ?? 30;
  const depth = props.depth ?? 5;
  return (
    <StyledSmallButton>
      <Button
        {...{
          ...props,
          height,
          depth,
          className: `btn-small ${props.className ?? ""}`,
        }}
      />
    </StyledSmallButton>
  );
}

const StyledSmallButton = styled.div`
  .button-top,
  .button-top > svg {
    font-size: 0.8rem;
  }
`;

function Button(props: Props) {
  const {
    onClick,
    onMouseEnter,
    onMouseLeave,
    buttonText,
    selected,
    disabled,
    className,
    color,
    icon,
    height = 50,
    width,
    depth = 10,
    borderRadius = 7,
    children,
  } = props;

  return (
    <StyledButton {...{ color, height, width, depth, borderRadius }}>
      <div className={`button-container ${className ?? ""}`}>
        <button
          className="game-btn"
          onClick={disabled ? () => null : onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          data-selected={selected}
          data-disabled={disabled}
        >
          <div className="button-top">
            {children && <div className="btn-children">{children}</div>}
            {icon && <SvgIcon className="icon" component={icon}></SvgIcon>}
            <div className="btn-text">
              {buttonText}
            </div>
          </div>
          <div className="button-bot"></div>
        </button>
      </div>
    </StyledButton>
  );
}

export default Button;

const StyledButton = styled.div<Partial<Props>>`
  .button-container {
    display: flex;
    justify-content: center;
    height: ${({ height, depth }) => height! + depth! + "px"};
    position: relative;
  }

  button {
    position: relative;
    height: ${({ height, depth }) => height! + depth! + "px"};
    width: ${(p) => (p.width ? `${p.width}px` : "")};
    color: rgb(81, 81, 81);
    font-size: 1.5rem;
    cursor: pointer;
    transform: scale(1);
    padding: 0;
    margin: 0px 5px;
  }

  button:hover {
    opacity: 1;
    filter: brightness(1.1);
  }

  button[data-disabled="true"] {
    opacity: 0.5;
    cursor: initial;
    filter: initial;
    transform: scale(1);
  }

  button:active:not([data-disabled="true"]) > .button-top,
  button[data-selected="true"]:not([data-disabled="true"]) > .button-top {
    margin-top: ${({ depth }) => depth! + "px"};
    color: #4c4c4c;

    .button-bot {
      bottom: 0;
    }
  }

  .button-top {
    overflow: hidden;
    height: ${(p) => p.height + "px"};
    white-space: nowrap;
    padding-left: 10px;
    padding-right: 10px;
    width: ${(p) => (p.width ? `${p.width}px` : "")};
    border: none;
    border-radius: ${({ borderRadius }) => `${borderRadius}px`};
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${(p) => p.color || "#e1e1e1"};
    position: relative;
    margin-bottom: ${({ depth }) => depth! + "px"};

    .btn-children {
      position: absolute;
      height: 100%;
      width: 100%;
    }

    .btn-text {
      z-index: 1;
      pointer-events: none;
      display: flex;
    }
  }

  .button-bot {
    border-radius: ${({ borderRadius }) => `${borderRadius}px`};
    background-color: ${(p) => p.color || "#e1e1e1"};
    filter: brightness(0.75);
    position: absolute;
    bottom: 0px;
    left: 0;
    height: calc(100% - ${({ depth }) => depth!}px);
    width: 100%;
    z-index: -1;
  }

  .icon {
    margin-right: 5px;
  }
`;
