import styled from "styled-components/macro";
import SvgIcon from "@mui/material/SvgIcon";

interface Props {
  icon: typeof SvgIcon;
  children: React.ReactNode;
}

const InputIcon = ({ icon, children }: Props) => {
  return (
    <StyledInputIcon>
      <>
        {children}
        <SvgIcon component={icon} className="input-icon" />
      </>
    </StyledInputIcon>
  );
};

export default InputIcon;

const StyledInputIcon = styled.div`
  position: relative;

  input {
    padding-left: 3rem;
  }

  .input-icon {
    position: absolute;
    left: 0;
    height: 100%;
    margin-left: 1rem;
  }
`;
