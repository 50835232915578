import styled from "styled-components/macro";
import SearchIcon from "@mui/icons-material/Search";
import InputIcon from "./InputIcon";

interface Props {
  onSearchValue: (value: string) => void;
}

const SearchInput = ({ onSearchValue }: Props) => {
  return (
    <StyledSearchInput>
      <div className="search-wrapper">
        <InputIcon icon={SearchIcon}>
          <input
            type="text"
            placeholder="search"
            title="search input"
            className="search-input"
            onChange={(el) => onSearchValue(el.target.value)}
          />
        </InputIcon>
      </div>
    </StyledSearchInput>
  );
};

export default SearchInput;

const StyledSearchInput = styled.div`
  .search-wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
  }
`;
