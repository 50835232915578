import styled from "styled-components/macro";
import BackButton from "./BackButton";
import SvgIcon from "@mui/material/SvgIcon";

interface Props {
  backTo?: string | null;
  icon?: typeof SvgIcon;
  children: string | React.ReactNode;
}
const Header = ({
  backTo,
  icon,
  children,
}: Props) => {
  return (
    <StyledHeader>
      <div className="header">
        <span className={`flex back-btn-container ${backTo !== null ? "" : "hide"}`}>
          <BackButton to={backTo} />
        </span>
        <h1 className={`header-title`}>
          {icon && <SvgIcon className="icon" component={icon}></SvgIcon>}
          <div className="header-title-text">{children}</div>
          {icon && <SvgIcon className="icon" component={icon}></SvgIcon>}
        </h1>
        <span className="flex"></span>
      </div>
    </StyledHeader>
  );
};

export default Header;

const StyledHeader = styled.div`
  .header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    .flex {
      flex: 1;
    }

    .back-btn-container {
      display: flex;
    }

    .hide {
      visibility: hidden;
    }

    .title-icon {
      margin-right: 10px;
      margin-left: 10px;
    }

    .title-icon.ready {
      animation: shake 0.5s infinite linear;
    }

    .header-title {
      display: flex;
      align-items: center;
    }

    .header-title {
      margin-left: 50px;
      margin-right: 50px;
    }
  }
`;
