import styled from "styled-components/macro";
import { btnColors } from "./shared-styles";
interface Props {
  readyTypes: string[];
}
const LaunchIndicator = ({ readyTypes }: Props) => {
  function buildReadyIndicator(type: string) {
    const isReady = readyTypes.includes(type);
    const className = `indicator ${isReady ? "ready" : ""}`;
    return (
      <div className="indicator-container" data-type={type}>
        {isReady && <div className="ready-glow"></div>}
        <div className={className}>
          <div className="indicator-icon indicator-btn-top">
            <div className="indicator-icon indicator-btn-bot" />
          </div>
        </div>
      </div>
    );
  }

  return (
    <StyledLaunchIndicator>
      {buildReadyIndicator("class")}
      {buildReadyIndicator("folder")}
      {buildReadyIndicator("set")}
    </StyledLaunchIndicator>
  );
};

export default LaunchIndicator;

const StyledLaunchIndicator = styled.div`
  display: flex;
  justify-content: center;

  .indicator-container {
    background-color: black;
    height: 3.5rem;
    width: 3rem;
    margin-left: 10px;
    margin-right: 10px;
    border-bottom-left-radius: 3rem;
    border-bottom-right-radius: 3rem;
    position: relative;

    .ready-glow {
      position: absolute;
      height: 200%;
      width: 200%;
      transform: translateX(-25%) translateY(-25%);
    }

    .indicator {
      margin-top: 0.5rem;
      filter: saturate(2);
    }

    .indicator-icon {
      width: 2.5rem;
      height: 2.5rem;
      border-radius: 50%;
      margin: auto;
    }
    .indicator:not(.ready) {
      opacity: 0.5;
      filter: brightness(1);
    }

    .indicator-btn-top {
      margin-bottom: 5px;
    }

    .indicator-btn-bot {
      position: absolute;
      transform: translateY(3px);
      filter: brightness(0.75);
      z-index: -1;
    }
  }

  .indicator-container[data-type="class"] {
    .ready-glow {
      background: radial-gradient(
        ${btnColors.class},
        rgba(255, 255, 255, 0) 50%
      );
    }
    .indicator-icon {
      background-color: ${btnColors.class};
    }
  }
  .indicator-container[data-type="folder"] {
    .ready-glow {
      background: radial-gradient(
        ${btnColors.folder},
        rgba(255, 255, 255, 0) 50%
      );
    }
    .indicator-icon {
      background-color: ${btnColors.folder};
    }
  }
  .indicator-container[data-type="set"] {
    .ready-glow {
      background: radial-gradient(${btnColors.set}, rgba(255, 255, 255, 0) 50%);
    }
    .indicator-icon {
      background-color: ${btnColors.set};
    }
  }
`;
