import styled from "styled-components/macro";
import starryBackground from "./TheGame/assets/imgs/starry_background.svg";
import GamepadIcon from '@mui/icons-material/Gamepad';
import { useNavigate, useParams } from "react-router-dom";
import AppHeader from "./AppHeader";
import { useEffect, useState } from "react";
import PresentationModal from "./PresentationModal";
import { btnColors } from "./shared-styles";
import MissionButton from "./MissionButton";
import MissionGuy from "./MissionGuy";
import Header from "./Header";
import { useAuthState } from "react-firebase-hooks/auth";
import { getAuth } from "firebase/auth";
import LoginModal from "./LoginModal";

const auth = getAuth();

interface Mission {
  name: string | React.ReactNode,
  label: string,
  onClick: VoidFunction,
  color: string,
}

const MissionSelect = () => {
  const [user] = useAuthState(auth);
  const { setId } = useParams();
  const navigate = useNavigate();
  const [showPresentation, setShowPresentation] = useState(false);
  const [selectedMission, setSelectedMission] = useState<Mission | null>(null);
  const [guyColor, setGuyColor] = useState<string | null>(null);
  function dismiss() {
    setShowPresentation(false);
  }

  useEffect(() => {
    // very hacky workaround for scrollbar showing when mission guy is in the corner
    document.documentElement.style.setProperty('--overflow', 'hidden');
  }, []);
  useEffect(() => {
    if (selectedMission && user) {
      selectedMission.onClick();
      setSelectedMission(null);
    }
  }, [selectedMission, user]);

  const missions = [
    {
      name:
        <>Mission<br />Briefing</>,
      label: "Mission Briefing",
      onClick: () => setShowPresentation(true),
      color: btnColors.presentation,
    },
    {
      name:
        "Flashcards",
      label: "Flashcards",
      onClick: () => navigate(`./flashcards`),
      color: btnColors.flashcards,
    },
    {
      name: <>Play<br />Gravity</>,
      label: "Play Gravity",
      onClick: () => navigate(`/gravity/${setId}`),
      color: btnColors.gravity
    },
  ];
  const missionButton = (props: Mission, key: number, hoverCallback: (color: string | null) => void) => {
    return (
      <MissionButton {...{
        ...props, onClick: user ? props.onClick : () => setSelectedMission(props), index: key, key, hoverCallback
      }} />
    )
  };

  return (
    <StyledMissionSelect>
      <AppHeader>
        <MissionGuy color={guyColor} />
        <div className="mission-select">
          <Header backTo={user && "home"}>{setId}</Header>
          <h2>
            <GamepadIcon />
            <div className="mission-select-heading">Mission Select</div>
            <GamepadIcon />
          </h2>
          <div className="mission-list">
            {
              missions.map((mission, i) => {
                return missionButton(mission, i, (color) => {
                  setGuyColor(color);
                });
              })
            }
          </div>
          {showPresentation && !!setId && (
            <PresentationModal
              dismiss={dismiss}
              setId={setId}
            />
          )}
          {selectedMission && <LoginModal cancel={() => setSelectedMission(null)} loginText={selectedMission!.label} />}

        </div>
      </AppHeader>
    </StyledMissionSelect>
  );
};

export default MissionSelect;

export const StyledMissionSelect = styled.div`
  background-image: url(${starryBackground});
  background-repeat: repeat;
  background-size: cover;
  background-position: center;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

  .app-content-container {
    width: -webkit-fill-available;
  }

  .mission-select {
    flex: 1;
    display: flex;
    flex-direction: column;
    text-align: center;
    h2 {
      display: flex;
      justify-content: center;
      align-items: center;
      .mission-select-heading {
        margin-left: 20px;
        margin-right: 20px;
      }
      @keyframes unblur {
        0% {
            filter: blur(10px);
        }
        100% {
            filter: none;
        }
      }

      animation: unblur 0.8s ease forwards;
    }

    .mission-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
  }
}
`;

interface SatProps {
  color: string,
  reversed: boolean,
}

export const StyledSatellite = styled.div.attrs<SatProps>(({ color, reversed }) => ({
  style: {
    animation: `orbit 5s infinite linear ${reversed ? 'reverse' : ''}`,
    animationDelay: `-${Math.random() * 10}s`,
    color: color,
  },
})) <SatProps>`
  position: absolute;
  width: 100%;
  height: 100%;

  @keyframes orbit {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .satellite {
    opacity: var(--satellite-scale);
    transform: scale(var(--satellite-scale)) rotate(${({ reversed }) => reversed ? 225 : 45}deg);
    transition: opacity 0.15s, transform 0.5s;
    filter: brightness(0.8) saturate(3);
}

`;