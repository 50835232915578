import styled from "styled-components/macro";
import Modal from "./TheGame/Modal";
import { colors, niceColors } from "./shared-styles";
import LoginContent from './LoginContent';

interface Props {
  loginTitle?: string;
  loginText?: string;
  cancel: VoidFunction;
}

function LoginModal({ loginTitle, loginText, cancel }: Props) {
  return (
    <StyledLoginModal>
      {(
        <Modal dismiss={cancel}>
          <LoginContent {...{
            loginTitle,
            loginText,
          }} />
        </Modal>
      )}
    </StyledLoginModal>
  );
}

export default LoginModal;

const StyledLoginModal = styled.div`
  .close {
    margin-top: 20px;
    .button-top{
      color: black;
    }
  }

  .mission-heading {
    margin-left: 20px;
    margin-right: 20px;
  }

  .mission-subheading {
    margin-top: -20px;
    margin-bottom: 10px;
  }

  .set-items {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(375px, 1fr));

    .item-wrapper {
      display: flex;
      /* flex: 1 1 375px; */
      height: 120px;
      margin-block: 5px;

      .item-wrapper-label {
        width: 50px;
        position: relative;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        .remove-item-btn {
          position: absolute;
          right: 0;
          top: 3px;
        }
      }
    }
  }

  .modal{.background-color: orange}

  .modal-child {
    height: 70vh;
    overflow: auto;
  }

  /* Define scrollbar styles */
  .modal-child::-webkit-scrollbar {
    width: 12px; /* Width of the scrollbar */
  }

  /* Track */
  .modal-child::-webkit-scrollbar-track {
    background: #e6d3f0; /* Color of the scrollbar track */
    border-radius: 8px; /* Rounded corners for the scrollbar track */
  }

  /* Handle */
  .modal-child::-webkit-scrollbar-thumb {
    background: #a989c5; /* Color of the scrollbar handle */
    border-radius: 8px; /* Rounded corners for the scrollbar track */
  }

  /* Handle on hover */
  .modal-child::-webkit-scrollbar-thumb:hover {
    background: #7a5596; /* Color of the scrollbar handle on hover */
  }

  .set-items input,
  .add-inputs input {
    font-size: 1rem;
    padding-block: 0.1rem;
    margin-block: 0.4rem;

    &#item-chinese {
      background-color: ${colors.asteroid};
      color: ${niceColors.darkPurple};
    }

    &#item-english {
      background-color: ${niceColors.darkPurple};
    }
  }

  .app-content-container {
    width: 100%;

    .app-content {
      justify-content: center;
      align-items: center;

      .edit-header {
        width: min(900px, 100%);
      }
    }
  }
`;
