import { get, ref, getDatabase, update, set, onValue } from 'firebase/database';
import { IClassDef } from './EditClass';
import { ItemData } from './Set/EditSet';
import { deleteObject, ref as storageRef, getStorage, uploadBytesResumable, getBlob } from 'firebase/storage';
import { nanoid } from 'nanoid';

export const adminPlurals: { [key: string]: string } = {
    class: 'classes',
    folder: 'folders',
    set: 'sets',
    user: 'users',
}

const db = getDatabase()
const rootRef = ref(db);
const storage = getStorage();

async function runUpdates(updates: (Promise<{ [x: string]: any; }> | { [x: string]: any })[]) {
    const updateObjs = (await Promise.all(updates)) as Record<string, any>[];
    const updateObj = updateObjs.reduce((result, obj) => ({ ...result, ...obj }), {});
    return update(rootRef, updateObj);
}

export async function renameClass(from: string, to: string) {
    await set(ref(db, `classes/${to}`), true);
    const updates = [
        // classes
        {
            [`classes/${from}`]: null,
            [`classes/${to}`]: true,
        },
        // classDefs
        get(ref(db, `classDefs/${from}`)).then((snapshot) => {
            const def = snapshot.val() ?? {};
            return {
                [`classDefs/${from}`]: null,
                [`classDefs/${to}`]: def,
            };
        }),
        // classUsers
        get(ref(db, `classUsers/${from}`)).then((snapshot) => {
            const users = snapshot.val() ?? {};
            const updateObj = {
                [`classUsers/${from}`]: null,
                [`classUsers/${to}`]: users,
            };
            // userClasses
            for (const user of Object.keys(users)) {
                updateObj[`userClasses/${user}/${from}`] = null;
                updateObj[`userClasses/${user}/${to}`] = true;
            }
            return updateObj;
        }),
    ];
    return runUpdates(updates)
        .catch(async error => {
            await set(ref(db, `classes/${to}`), null);
            throw error;
        });
}

function getDeleteClassUpdates(id: string) {
    return [
        // classes / classDefs / classUsers
        {
            [`classes/${id}`]: null,
            [`classDefs/${id}`]: null,
            [`classUsers/${id}`]: null,
        },
        get(ref(db, `classUsers/${id}`)).then((snapshot) => {
            const users = snapshot.val() ?? {};
            const updateObj = {} as Record<string, null>;
            // userClasses
            for (const user of Object.keys(users)) {
                updateObj[`userClasses/${user}/${id}`] = null;
            }
            return updateObj;
        }),
    ];
}
export async function deleteClass(id: string) {
    const updates = getDeleteClassUpdates(id);
    return runUpdates(updates);
}

export async function renameFolder(from: string, to: string) {
    await set(ref(db, `folders/${to}`), true);
    const updates = [
        // folders
        {
            [`folders/${from}`]: null,
            [`folders/${to}`]: true,
        },
        // classDefs
        get(ref(db, `classDefs`)).then((snapshot) => {
            const classDefs = snapshot.val() as { [classId: string]: IClassDef } ?? {};
            const classes = Object.entries(classDefs).filter(([classId, { folders = {}, sets }]) => {
                return Object.keys(folders).includes(from)
            })
            return classes.reduce((result, [classId]) => ({
                ...result,
                [`classDefs/${classId}/folders/${from}`]: null,
                [`classDefs/${classId}/folders/${to}`]: true,
            }), {});
        }),
        // folderSets
        get(ref(db, `folderSets/${from}`)).then((snapshot) => {
            const folderSets = snapshot.val() ?? {};
            return {
                [`folderSets/${from}`]: null,
                [`folderSets/${to}`]: folderSets,
            };
        }),
    ];
    return runUpdates(updates)
        .catch(async error => {
            await set(ref(db, `folders/${to}`), null);
            throw error;
        });
}

function getDeleteFolderUpdates(id: string) {
    return [
        // folders / folderSets
        {
            [`folders/${id}`]: null,
            [`folderSets/${id}`]: null,
        },
        // classDefs
        get(ref(db, `classDefs`)).then((snapshot) => {
            const classDefs = snapshot.val() as { [classId: string]: IClassDef } ?? {};
            const classes = Object.entries(classDefs).filter(([classId, { folders = {}, sets }]) => {
                return Object.keys(folders).includes(id)
            })
            return classes.reduce((result, [classId]) => ({
                ...result,
                [`classDefs/${classId}/folders/${id}`]: null,
            }), {});
        }),
    ];
}
export async function deleteFolder(id: string) {
    const updates = getDeleteFolderUpdates(id);
    return runUpdates(updates);
}

export async function renameSet(from: string, to: string) {
    await set(ref(db, `sets/${to}`), true);
    const updates = [
        // sets
        {
            [`sets/${from}`]: null,
            [`sets/${to}`]: true,
        },
        // classDefs
        get(ref(db, `classDefs`)).then((snapshot) => {
            const classDefs = snapshot.val() as { [classId: string]: IClassDef } ?? {};
            const classes = Object.entries(classDefs).filter(([classId, { folders, sets = {} }]) => {
                return Object.keys(sets).includes(from)
            })
            return classes.reduce((result, [classId]) => ({
                ...result,
                [`classDefs/${classId}/sets/${from}`]: null,
                [`classDefs/${classId}/sets/${to}`]: true,
            }), {});
        }),
        // folderSets
        get(ref(db, `folderSets`)).then((snapshot) => {
            const folderSets = snapshot.val() as { [folder: string]: { [set: string]: boolean } };
            const folders = Object.entries(folderSets).filter(([folder, sets]) => {
                return Object.keys(sets).includes(from)
            })
            return folders.reduce((result, [folder]) => ({
                ...result,
                [`folderSets/${folder}/${from}`]: null,
                [`folderSets/${folder}/${to}`]: true,
            }), {});
        }),
        // setItems
        get(ref(db, `setItems/${from}`)).then((snapshot) => {
            const setItems = snapshot.val() ?? {};
            return {
                [`setItems/${from}`]: null,
                [`setItems/${to}`]: setItems,
            };
        }),
        // highscores
        get(ref(db, `highscores/${from}`)).then((snapshot) => {
            const highscores = snapshot.val() ?? {};
            const updateObj = {
                [`highscores/${from}`]: null,
                [`highscores/${to}`]: highscores,
            };
            return updateObj;
        }),
        get(ref(db, `weeklyHighscores/${from}`)).then((snapshot) => {
            const highscores = snapshot.val() ?? {};
            const updateObj = {
                [`weeklyHighscores/${from}`]: null,
                [`weeklyHighscores/${to}`]: highscores,
            };
            return updateObj;
        }),
        get(ref(db, `monthlyHighscores/${from}`)).then((snapshot) => {
            const highscores = snapshot.val() ?? {};
            const updateObj = {
                [`monthlyHighscores/${from}`]: null,
                [`monthlyHighscores/${to}`]: highscores,
            };
            return updateObj;
        }),
    ];
    return runUpdates(updates)
        .catch(async error => {
            await set(ref(db, `sets/${to}`), null);
            throw error;
        });
}

function getDeleteSetUpdates(id: string) {
    return [
        // sets / setItems / highscores
        {
            [`sets/${id}`]: null,
            [`setItems/${id}`]: null,
            [`highscores/${id}`]: null,
        },
        // folderSets
        get(ref(db, `folderSets`)).then((snapshot) => {
            const folderSets = snapshot.val() as { [folder: string]: { [set: string]: boolean } } ?? {};
            const folders = Object.entries(folderSets).filter(([folder, sets]) => {
                return Object.keys(sets).includes(id)
            })
            return folders.reduce((result, [folder]) => ({
                ...result,
                [`folderSets/${folder}/${id}`]: null,
            }), {});
        }),
        // classDefs
        get(ref(db, `classDefs`)).then((snapshot) => {
            const classDefs = snapshot.val() as { [classId: string]: IClassDef } ?? {};
            const classes = Object.entries(classDefs).filter(([classId, { folders, sets = {} }]) => {
                return Object.keys(sets).includes(id)
            })
            return classes.reduce((result, [classId]) => ({
                ...result,
                [`classDefs/${classId}/sets/${id}`]: null,
            }), {});
        }),
    ]
}
export async function deleteSet(id: string) {
    const updates = getDeleteSetUpdates(id);
    return get(ref(db, `setItems/${id}`)).then(snapshot => {
        const setItems = snapshot.val() as Record<string, ItemData> ?? {}
        const imgsToDelete = Object.keys(setItems).filter(id => setItems[id].i)
        return runUpdates(updates)
            .then(() => {
                // delete images
                return Promise.all(imgsToDelete.map(deleteImage)).then(() => console.log('deleted images: ', imgsToDelete))
            });
    })
}

export async function deleteImage(id: string) {
    const ref = storageRef(storage, `images/${id}_200x200`);
    return deleteObject(ref);
}

async function getImageBlob(id: string) {
    const ref = storageRef(storage, `images/${id}_200x200`);
    return getBlob(ref);
}

export async function renameUser(from: string, to: string) {
    await set(ref(db, `users/${to}`), true);
    const updates = [
        // users
        {
            [`users/${from}`]: null,
            [`users/${to}`]: true,
        },
        // userClasses
        get(ref(db, `userClasses/${from}`)).then((snapshot) => {
            const classes = snapshot.val() ?? {};
            const updateObj = {
                [`userClasses/${from}`]: null,
                [`userClasses/${to}`]: classes,
            };
            // classUsers
            for (const classId of Object.keys(classes)) {
                updateObj[`classUsers/${classId}/${from}`] = null;
                updateObj[`classUsers/${classId}/${to}`] = true;
            }
            return updateObj;
        }),
        // highscores
        get(ref(db, `highscores`)).then((snapshot) => {
            const highscores = snapshot.val() as { [set: string]: { [user: string]: number } } ?? {};
            const sets = Object.entries(highscores).filter(([set, users]) => {
                return Object.keys(users).includes(from)
            })
            return sets.reduce((result, [set, userscores]) => ({
                ...result,
                [`highscores/${set}/${from}`]: null,
                [`highscores/${set}/${to}`]: userscores[from],
            }), {});
        }),
        // weeklyHighscores
        get(ref(db, `weeklyHighscores`)).then((snapshot) => {
            const highscores = snapshot.val() as { [set: string]: { [user: string]: number } } ?? {};
            const sets = Object.entries(highscores).filter(([set, users]) => {
                return Object.keys(users).includes(from)
            })
            return sets.reduce((result, [set, userscores]) => ({
                ...result,
                [`weeklyHighscores/${set}/${from}`]: null,
                [`weeklyHighscores/${set}/${to}`]: userscores[from],
            }), {});
        }),
        // monthlyHighscores
        get(ref(db, `monthlyHighscores`)).then((snapshot) => {
            const highscores = snapshot.val() as { [set: string]: { [user: string]: number } } ?? {};
            const sets = Object.entries(highscores).filter(([set, users]) => {
                return Object.keys(users).includes(from)
            })
            return sets.reduce((result, [set, userscores]) => ({
                ...result,
                [`monthlyHighscores/${set}/${from}`]: null,
                [`monthlyHighscores/${set}/${to}`]: userscores[from],
            }), {});
        }),
    ];
    return runUpdates(updates)
        .catch(async error => {
            await set(ref(db, `users/${to}`), null);
            throw error;
        });
}

function getDeleteUserUpdates(id: string) {
    return [
        // users 
        {
            [`users/${id}`]: null,
        },
        // userClasses
        get(ref(db, `userClasses/${id}`)).then((snapshot) => {
            const classes = snapshot.val() ?? {};
            const updateObj = {
                [`userClasses/${id}`]: null,
            };
            // classUsers
            for (const classId of Object.keys(classes)) {
                updateObj[`classUsers/${classId}/${id}`] = null;
            }
            return updateObj;
        }),
        // highscores
        get(ref(db, `highscores`)).then((snapshot) => {
            const highscores = snapshot.val() as { [set: string]: { [user: string]: number } } ?? {};
            const sets = Object.entries(highscores).filter(([set, users]) => {
                return Object.keys(users).includes(id)
            })
            return sets.reduce((result, [set, users]) => ({
                ...result,
                [`highscores/${set}/${id}`]: null,
            }), {});
        }),
    ]
}
export const deleteUser = async (id: string) => runUpdates(getDeleteUserUpdates(id));

export async function deleteItems(items: { id: string, type: string }[]) {
    const updates = items.reduce((arr, { id, type }) => {
        let newUpdates: Promise<{ [x: string]: any; }>[] | { [x: string]: any; }[] = [];
        if (type === 'user') newUpdates = getDeleteUserUpdates(id);
        else if (type === 'class') newUpdates = getDeleteClassUpdates(id);
        else if (type === 'folder') newUpdates = getDeleteFolderUpdates(id);
        else if (type === 'set') newUpdates = getDeleteSetUpdates(id);
        return [...arr, ...newUpdates]
    }, [] as Promise<{ [x: string]: any; }>[] | { [x: string]: any; }[])

    return runUpdates(updates);
}

export async function duplicateSet(from: string,
    onUploadedChanged?: (uploaded: Record<string, File>, totalFiles: number) => void,
) {
    const dbRef = ref(db, `sets`);
    const setIds = await get(dbRef).then((snapshot) => {
        return Object.keys(snapshot.val());
    });
    const setItems = await get(ref(db, `setItems/${from}`)).then((snapshot) => {
        return (snapshot.val() ?? {}) as Record<string, ItemData>;
    })

    const newSetItems: Record<string, any> = {}
    const filesToCreate: Record<string, File> = {}
    await Promise.all(Object.entries(setItems).map(async ([oldId, val]) => {
        const newId = nanoid(12)
        newSetItems[newId] = val
        if (val.i) {
            await getImageBlob(oldId).then(blob => {
                filesToCreate[newId] = blobToFile(blob, oldId)
            })
        }
    }))

    const copyIndex = setIds.filter(id => id.match(new RegExp(`^${from} Copy( \\d+)?$`, "i"))).length;
    const to = from + ' Copy' + (copyIndex === 0 ? '' : ` ${copyIndex + 1}`);

    await set(ref(db, `sets/${to}`), true);
    const updates = [
        // sets && setItems
        {
            [`sets/${to}`]: true,
            [`setItems/${to}`]: newSetItems,
        },
        // classDefs
        get(ref(db, `classDefs`)).then((snapshot) => {
            const classDefs = snapshot.val() as { [classId: string]: IClassDef } ?? {};
            const classes = Object.entries(classDefs).filter(([classId, { folders, sets = {} }]) => {
                return Object.keys(sets).includes(from)
            })
            return classes.reduce((result, [classId]) => ({
                ...result,
                [`classDefs/${classId}/sets/${to}`]: true,
            }), {});
        }),
        // folderSets
        get(ref(db, `folderSets`)).then((snapshot) => {
            const folderSets = snapshot.val() as { [folder: string]: { [set: string]: boolean } };
            const folders = Object.entries(folderSets).filter(([folder, sets]) => {
                return Object.keys(sets).includes(from)
            })
            return folders.reduce((result, [folder]) => ({
                ...result,
                [`folderSets/${folder}/${to}`]: true,
            }), {});
        }),
    ];

    return runUpdates(updates)
        .then(() => {
            // duplicate images
            return uploadFiles({
                files: Object.values(filesToCreate),
                onUploadedChanged,
                setId: to,
                ids: Object.keys(filesToCreate)
            }).then(() => {
                console.log('created duplicate images: ', filesToCreate)
                return to;
            })
        })
        .catch(async error => {
            await deleteSet(to);
            throw error;
        });
}

interface UploadFilesProps {
    files: File[],
    onProgressChanged?: (uploading: Record<string, number>) => void,
    onUploadedChanged?: (uploaded: Record<string, File>, totalFiles: number) => void,
    setId?: string,
    ids?: string[],
}

export async function uploadFiles({
    files,
    onProgressChanged,
    onUploadedChanged,
    setId,
    ids,
}: UploadFilesProps) {
    const uploading: Record<string, number> = {};
    const uploaded: Record<string, File> = {};

    let resolvePromise: (result: Record<string, File>) => void;
    const promise = new Promise<Record<string, File>>((resolve, reject) => {
        resolvePromise = resolve
    });

    const onFileUploadComplete = (file: File, key: string) => {
        delete uploading[file.name];
        uploaded[key] = file;

        if (Object.keys(uploading).length > 0) {
            onProgressChanged?.(uploading);
            onUploadedChanged?.(uploaded, files.length);
        } else {
            window.setTimeout(() => resolvePromise(uploaded), 3000);
        }
    }

    files.forEach((file, index) => {
        const metadata = { customMetadata: { ...(setId ? { setId } : {}) } };
        const key = ids?.[index] ?? nanoid(12);
        const sRef = storageRef(storage, "images/" + key);
        const uploadTask = uploadBytesResumable(sRef, file, metadata);

        // Listen for state changes, errors, and completion of the upload.
        uploadTask.on(
            "state_changed",
            (snapshot) => {
                const progress =
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                console.log("Upload is " + progress + "% done");

                uploading[file.name] = progress;
                onProgressChanged?.(uploading);

                switch (snapshot.state) {
                    case "paused":
                        console.log("Upload is paused");
                        break;
                    case "running":
                        console.log("Upload is running");
                        break;
                }
            },
            (error) => {
                console.error(error);
                onFileUploadComplete(file, key);
            },

            () => {
                const onSuccess = () => onFileUploadComplete(file, key)
                if (setId) {
                    const cancel = onValue(
                        ref(db, `setItems/${setId}/${key}/i`),
                        () => {
                            onSuccess();
                            cancel();
                        }
                    );
                } else {
                    onSuccess();
                }
            }
        );
    })

    return promise;
}

function blobToFile(blob: Blob, fileName: string) {
    return new File(
        [blob as any],
        fileName,
        {
            lastModified: new Date().getTime(),
            type: blob.type
        }
    )
}