import styled from "styled-components/macro";
import GameProgressItem from "./GameProgressItem";

interface GameProgressProps {
  level: number;
  score: number;
  streak?: number;
}

function GameProgress({ level, score, streak }: GameProgressProps) {
  return (
    <StyledGameProgress>
      <GameProgressItem
        className="level"
        color="orange"
        value={level.toLocaleString()}
      >
        level
      </GameProgressItem>
      <GameProgressItem
        className="score"
        color="yellow"
        value={score.toLocaleString()}
      >
        score
      </GameProgressItem>
      {streak != null && (
        <GameProgressItem
          className="streak"
          color="limegreen"
          value={streak.toLocaleString()}
        >
          streak
        </GameProgressItem>
      )}
    </StyledGameProgress>
  );
}

export default GameProgress;

const StyledGameProgress = styled.div.attrs({
  className: "game-progress",
})`
  display: flex;
  color: white;

  .level {
    padding-right: 20px;
  }

  .streak {
    padding-left: 20px;
  }
`;
