import { useState } from "react";
import { btnColors, btnIcons } from "../shared-styles";
import { SmallButton } from "../TheGame/Button";
import RenameItemModal from "./RenameItemModal";

export interface RenameItemButtonProps {
  existingId: string;
  submit: (id: string) => Promise<void>;
  itemType: string;
}

function RenameItemButton(props: RenameItemButtonProps) {
  const [isRenaming, setIsRenaming] = useState(false);
  return (
    <>
      <SmallButton
        icon={btnIcons.rename}
        onClick={() => {
          setIsRenaming(true);
        }}
        color={btnColors.rename}
        buttonText="Rename"
      ></SmallButton>
      {isRenaming && (
        <RenameItemModal
          {...props}
          cancel={() => {
            setIsRenaming(false);
          }}
        />
      )}
    </>
  );
}

export default RenameItemButton;
