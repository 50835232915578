import styled from "styled-components/macro";
import { useNavigate } from "react-router-dom";
import { getAuth } from "firebase/auth";
import { MediumButton } from "./TheGame/Button";
import { useAuthState } from "react-firebase-hooks/auth";
import { getDatabase, ref, get, DatabaseReference } from "firebase/database";
import AppHeader from "./AppHeader";
import { btnIcons, btnColors } from "./shared-styles";
import HomeHeader from "./HomeHeader";
import LaunchPanel from "./LaunchPanel";
import { useEffect, useState } from "react";
import useIsTeacher from "./useIsTeacher";
import ReportIcon from "@mui/icons-material/Report";
import SlideDown from "./SlideDown";
import starryBackground from "./TheGame/assets/imgs/starry_background.svg";

const db = getDatabase();
const auth = getAuth();

const Home = () => {
  const [user] = useAuthState(auth);
  const isTeacher = useIsTeacher(auth);
  const navigate = useNavigate();

  const [classes, setClasses] = useState<string[] | null>(null);

  useEffect(() => {
    if (user && isTeacher !== undefined) {
      let dbRef: DatabaseReference;
      if (isTeacher) {
        dbRef = ref(db, `classes`);
      } else {
        dbRef = ref(db, `userClasses/${user?.uid}`);
      }
      get(dbRef).then((snapshot) => {
        const val = snapshot.val();
        setClasses(Object.keys(val ?? {}));
      });
    }
  }, [isTeacher, user, user?.uid]);

  const classBtn = (cl: string, i: number) => (
    <SlideDown key={i} delay={Math.random() * 0.15} duration={0.25}>
      <MediumButton
        className="class-btn"
        key={i}
        onClick={() => navigate(`class/${cl}`)}
        buttonText={cl}
        icon={btnIcons.class}
        color={btnColors.class}
      ></MediumButton>
    </SlideDown>
  );

  return (
    <StyledHome>
      <AppHeader>
        <div className="home">
          <HomeHeader />
          <div className="home-classes">
            {classes && classes.map((cl, i) => classBtn(cl, i))}
            {classes?.length === 0 && (
              <div className="no-classes">
                <ReportIcon />
                <span>NO CLASSES</span>
              </div>
            )}
          </div>
        </div>
        <LaunchPanel readyTypes={[]} />
      </AppHeader>
    </StyledHome>
  );
};

export default Home;

const StyledHome = styled.div`
  background-image: url(${starryBackground});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  color: white;

  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;

  .home {
    flex: 1;
    display: flex;
    flex-direction: column;

    .home-classes {
      justify-content: center;
      margin-bottom: 100px;
      display: flex;
      flex-wrap: wrap;
      .button-container {
        margin-bottom: 20px;
      }
    }

    .no-classes {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
`;
