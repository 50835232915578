import styled from "styled-components/macro";
import Button, { SmallButton } from "../../TheGame/Button";
import { useNavigate } from "react-router-dom";
import { ref, get, getDatabase } from "firebase/database";
import { useEffect, useState } from "react";
import ModalChild from "../../TheGame/ModalChild";
import Modal from "../../TheGame/Modal";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import { btnColors } from "../../shared-styles";
import AdminGroup from "../AdminGroup";
import { getAuth } from "firebase/auth";
import useIsAdmin from "../../useIsAdmin";

const db = getDatabase();
const auth = getAuth();

interface Props {
  dismiss: VoidFunction;
  folders: string[];
  sets: string[];
}

function HighscoresModal({ dismiss, folders, sets }: Props) {
  const isAdmin = useIsAdmin(auth);
  const [selectedSet, setSelectedSet] = useState("");
  const [idFilter, setIdFilter] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    // get ALL sets in the class (including sets in folders)
    // sets in folders
    const folderSetPromises = folders.map((folder) => {
      return get(ref(db, `folderSets/${folder}`)).then((snapshot) => {
        const obj = snapshot.val() ?? {};
        return Object.keys(obj);
      });
    });
    Promise.all(folderSetPromises).then((folderSets) => {
      const allSets = [...folderSets.flat(), ...sets];
      setIdFilter(allSets.join("|"));
    });
  }, [folders, sets]);

  return (
    <StyledHighscoresModal>
      {!!idFilter && (
        <Modal>
          <h1>View Highscores</h1>
          <ModalChild>
            <AdminGroup
              {...{
                entityName: "set",
                itemBuilder: (id) => {
                  const selected = selectedSet === id;
                  return (
                    <SmallButton
                      key={id}
                      color={btnColors.set}
                      buttonText={id}
                      selected={selected}
                      icon={
                        selected
                          ? RadioButtonCheckedIcon
                          : RadioButtonUncheckedIcon
                      }
                      onClick={() => setSelectedSet(id)}
                    />
                  );
                },
                idFilter: idFilter,
              }}
            />
          </ModalChild>
          <Button
            width={200}
            buttonText="View"
            className="view-highscores-btn"
            disabled={!selectedSet}
            onClick={() => {
              navigate(`highscores/${selectedSet}`);
            }}
          ></Button>
          {isAdmin && (
            <div className="view-warning">
              Viewing will lose any unsaved edits.
            </div>
          )}
          <button className="cancel" onClick={dismiss}>
            Cancel
          </button>
        </Modal>
      )}
    </StyledHighscoresModal>
  );
}

export default HighscoresModal;

const StyledHighscoresModal = styled.div`
  .admin-group {
    background: none;
    max-height: 60vh;
    overflow-y: scroll;
  }

  .group-header {
    display: none;
  }

  .view-warning {
    text-transform: none;
    font-size: 0.6rem;
    margin-top: 5px;
  }

  .cancel {
    margin-top: 20px;
  }

  .view-highscores-btn {
    margin-top: 30px;
  }
`;
