import styled from "styled-components/macro";
import { getDatabase, ref, onValue, set } from "firebase/database";
import { useState, useEffect, useMemo } from "react";
import AddItemModal from "./AddItemModal";
import { SmallButton } from "../TheGame/Button";
import AddIcon from "@mui/icons-material/Add";
import { adminPlurals } from "./adminUtils";
import { niceColors } from "../shared-styles";

const db = getDatabase();

interface Props {
  onLoaded?: VoidFunction;
  entityName: string;
  canAdd?: boolean;
  onItemAdd?: (id: string) => void;
  additionalActions?: React.ReactNode[];
  itemBuilder: (id: string) => React.ReactNode;
  idFilter?: string;
  grouping?: (a: string) => string;
}

export interface EditingItem {
  isNew: boolean;
  id?: string;
}

async function addItem(key: string, id: string) {
  return set(ref(db, `${key}/${id}`), true).catch((err) => console.error(err));
}

const AdminGroup = (props: Props) => {
  const {
    entityName,
    canAdd = false,
    additionalActions = [],
    itemBuilder,
    onLoaded,
    onItemAdd,
    grouping,
    idFilter,
  } = props;
  const dbKey = adminPlurals[entityName];
  const [items, setItems] = useState<string[]>([]);
  const [addingItem, setAddingItem] = useState(false);

  const filteredItems = useMemo(() => {
    return items.filter(
      (item) => !idFilter || item.match(new RegExp(idFilter, "i"))
    );
  }, [idFilter, items]);

  const groups = useMemo(() => {
    if (grouping) {
      return filteredItems.reduce((obj, item) => {
        const group = grouping(item);
        return { ...obj, [group]: [...(obj[group] ?? []), item] };
      }, {} as Record<string, string[]>);
    } else return { default: filteredItems };
  }, [filteredItems, grouping]);

  useEffect(() => {
    const dbRef = ref(db, dbKey ?? adminPlurals[entityName]);
    return onValue(
      dbRef,
      (snapshot) => {
        const val = snapshot.val();
        setItems(Object.keys(val ?? {}));
        onLoaded?.();
      },
      (error) => console.error(error)
    );
  }, [dbKey, entityName, onLoaded]);

  return (
    <StyledAdminGroup className="admin-group">
      <div className="group-header">
        <h2 className="group-name">{adminPlurals[entityName]}</h2>
        <div className="actions">
          {additionalActions}
          {canAdd && (
            <SmallButton
              onClick={() => setAddingItem(true)}
              className="add-item-btn"
              buttonText={`Add ${entityName}`}
              icon={AddIcon}
            />
          )}
        </div>
      </div>
      <div className="items">
        {Object.entries(groups)
          .sort(([g1], [g2]) => (g1 < g2 ? -1 : 1))
          .map(([groupName, groupItems], i) => {
            return (
              <div key={groupName}>
                {i > 0 && <hr className="groups-divider" />}

                <div className="item-group">
                  {groupItems.map((item) => itemBuilder(item))}
                </div>
              </div>
            );
          })}
      </div>
      {addingItem && (
        <AddItemModal
          itemType={entityName}
          existingIds={items}
          submit={(id) =>
            addItem(dbKey, id).then(() => {
              onItemAdd?.(id);
              setAddingItem(false);
            })
          }
          cancel={() => {
            setAddingItem(false);
          }}
        ></AddItemModal>
      )}
    </StyledAdminGroup>
  );
};

export default AdminGroup;

const StyledAdminGroup = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #3b2378;
  border-radius: 15px;
  margin-block: 10px;
  padding: 10px 20px;
  width: 100%;

  .group-header {
    display: flex;
    align-items: center;
    text-align: left;

    .group-icon {
      margin-right: 10px;
    }

    .group-name {
      width: 180px;
      margin-block: 0.5em;
      flex: 1;
    }

    .actions {
      display: flex;
      align-items: center;
    }
  }

  .groups-divider {
    width: 100%;
    height: 1px;
    background-color: ${niceColors.darkDarkPurple};
    border: none;
  }

  .item-group {
    display: flex;
    flex-wrap: wrap;
    margin-block: 10px;
    @media (max-width: 500px) {
      justify-content: center;
    }
  }
`;
