import styled from "styled-components/macro";
import { useRef } from "react";
import { SmallButton } from "../../TheGame/Button";
import ImageIcon from "@mui/icons-material/Image";
import { FilesUploader } from "./FilesUploader";

interface Props {
  height?: number;
  buttonText?: string | React.ReactNode;
  id: string;
  setId?: string;
  onUpload: () => void;
}

export function UploadImgButton({
  id,
  setId,
  onUpload,
  buttonText = "Upload File",
  height,
}: Props) {
  const inputEl = useRef<HTMLInputElement | null>(null);

  return (
    <StyledUploadImgButton>
      <SmallButton
        icon={ImageIcon}
        color="orange"
        onClick={() => inputEl.current!.click()}
        buttonText={buttonText}
        className="upload-btn"
        height={height}
      ></SmallButton>
      <FilesUploader
        setId={setId}
        inputRef={inputEl}
        multiple={false}
        id={id}
        onComplete={() => onUpload()}
      />
    </StyledUploadImgButton>
  );
}

const StyledUploadImgButton = styled.div``;
