import styled from "styled-components/macro";
import { useRef } from "react";
import { ItemData } from "./EditSet";
import { SmallButton } from "../../TheGame/Button";
import TableRowsIcon from "@mui/icons-material/TableRows";
import { nanoid } from "nanoid";

interface Props {
  onItemsRead: (items: ItemData[]) => void;
}
const csvStringToArray = (strData: string) => {
  //const objPattern = new RegExp(("(\\,|\\r?\\n|\\r|^)(?:\"([^\"]*(?:\"\"[^\"]*)*)\"|([^\\,\\r\\n]*))"),"gi");
  const objPattern = new RegExp(
    '(\\,|\\r?\\n|\\r|^)(?:"((?:\\\\.|""|[^\\\\"])*)"|([^\\,"\\r\\n]*))',
    "gi"
  );
  let arrMatches = null,
    arrData: string[][] = [[]];
  while ((arrMatches = objPattern.exec(strData))) {
    if (arrMatches[1].length && arrMatches[1] !== ",") arrData.push([]);
    arrData[arrData.length - 1].push(
      arrMatches[2]
        ? arrMatches[2].replace(new RegExp('[\\\\"](.)', "g"), "$1")
        : arrMatches[3]
    );
  }

  return arrData;
};

function LoadCsvButton({ onItemsRead }: Props) {
  async function loadFile(file: File) {
    const text = await file.text();
    const array = csvStringToArray(text);
    const t = +new Date();
    const data = array
      .filter(([c, e]) => c && e)
      .map(([c, e], i) => ({
        c: c,
        e: e,
        id: nanoid(12),
        t: t + i,
      }));
    onItemsRead(data);
    inputEl.current!.value = "";
  }

  const inputEl = useRef<HTMLInputElement | null>(null);

  return (
    <StyledLoadCsvButton>
      <SmallButton
        icon={TableRowsIcon}
        onClick={() => inputEl.current!.click()}
        buttonText="Load .csv"
        className="load-btn"
      ></SmallButton>
      <input
        ref={inputEl}
        type="file"
        id="csv-input"
        name="csv"
        title="csv"
        accept=".csv"
        onChange={({ target }) => loadFile(target.files![0])}
      />
    </StyledLoadCsvButton>
  );
}

export default LoadCsvButton;

const StyledLoadCsvButton = styled.div`
  display: flex;

  #csv-input {
    display: none;
  }
`;
