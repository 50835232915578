import styled from "styled-components/macro";

const LandBlobs = ({ color = '#754f44', key }: { color: string, key: number }) => {
    const dx = (key % 10) * 10;
    return (
        <StyledMissionLandBlobs className="land-blobs">
            <svg viewBox="0 0 200 200" width="100%" height="100%">
                {/* Land Blobs */}
                {/* Blob 1 */}
                <rect x={0 + dx % 40} y="25" width="70" height="50" rx="25" fill={color} />

                {/* Blob 2 */}
                <rect x={120 - dx % 30} y="50" width="150" height="40" rx="20" fill={color} />

                {/* Blob 3 */}
                {/* <circle cx="100" cy="150" r="30" fill={color} /> */}
                <rect x={0 + dx % 30} y="120" width="80" height="30" rx="15" fill={color} />
                <rect x={0 + dx % 30} y="130" width="60" height="30" rx="15" fill={color} />
                <rect x={0 + dx % 30} y="140" width="40" height="30" rx="15" fill={color} />

                {/* Blob 4 */}
                <circle cx="180" cy="170" r="70" fill={color} />

                {/* Blob 5 */}
                <circle cx="130" cy="0" r="25" fill={color} />
            </svg>
        </StyledMissionLandBlobs>
    )
};

export default LandBlobs;

export const StyledMissionLandBlobs = styled.div`
    position: absolute;
    circle {
        transition: transform 0.3s ease;
    }

    @keyframes wobble {
        0% {
            transform: translateX(0);
        }
        20% {
            transform: translateX(-3px);
        }
        80% {
            transform: translateX(3px);
        }
        100% {
            transform: translateX(0);
        }
    }

    &:hover {
        circle, rect {
            animation: wobble 4s linear forwards infinite;
            &:nth-child(odd) {
                animation-direction: reverse;
            }
        }
    }
`;
