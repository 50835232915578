import { useState } from "react";
import { btnColors } from "../shared-styles";
import { SmallButton } from "../TheGame/Button";
import CopyAllIcon from "@mui/icons-material/CopyAll";
import DuplicateSetModal from "./DuplicateSetModal";

export interface DuplicateSetButtonProps {
  id: string;
}

function DuplicateSetButton(props: DuplicateSetButtonProps) {
  const [isDuplicating, setIsDuplicating] = useState(false);
  return (
    <>
      <SmallButton
        color={btnColors.duplicate}
        buttonText="Duplicate"
        onClick={() => {
            setIsDuplicating(true);
        }}
        icon={CopyAllIcon}
      />
      {isDuplicating && (
        <DuplicateSetModal
          {...props}
          cancel={() => {
            setIsDuplicating(false);
          }}
        />
      )}
    </>
  );
}

export default DuplicateSetButton;
