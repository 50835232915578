import styled from "styled-components/macro";
import { useAuthState, } from "react-firebase-hooks/auth";
import { auth } from "./firebase";
import AppHeader from "./AppHeader";
import starryBackground from "./TheGame/assets/imgs/starry_background.svg";
import LoginContent from './LoginContent';

const Login = () => {
  useAuthState(auth);
  return (
    <StyledLogin>
      <AppHeader>
        <LoginContent loginTitle="gravity" />
      </AppHeader>
    </StyledLogin>
  );
};

export default Login;

const StyledLogin = styled.div`
  background-image: url(${starryBackground});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  .msg-container {
    height: 50px;
    display: flex;
    flex-direction: column;
  }

  .app-content {
    flex: 1;
  }
`;
