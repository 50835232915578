import styled from "styled-components/macro";
import { SmallButton } from "../../TheGame/Button";
import RocketIcon from "@mui/icons-material/Rocket";
import { useState } from "react";
import HighscoresModal from "./HighscoresModal";

interface Props {
  folders: string[];
  sets: string[];
}

function HighscoresButton({ folders, sets }: Props) {
  const [isSelectingSet, setIsSelectingSet] = useState(false);

  function dismiss() {
    setIsSelectingSet(false);
  }

  return (
    <StyledHighscoresButton>
      <SmallButton
        color="#ffff70"
        icon={RocketIcon}
        buttonText={"Highscores"}
        onClick={() => setIsSelectingSet(true)}
      />
      {isSelectingSet && (
        <HighscoresModal
          dismiss={dismiss}
          folders={folders}
          sets={sets}
        />
      )}
    </StyledHighscoresButton>
  );
}

export default HighscoresButton;

const StyledHighscoresButton = styled.div``;
