import redAsteroid from "./assets/imgs/asteroid_red.svg";
import greenAsteroid from "./assets/imgs/asteroid_green.svg";
import goldAsteroid from "./assets/imgs/asteroid_gold.svg";
import asteroidExplosion from "./assets/imgs/asteroid_explosion.gif";
import asteroidsExplainer from "./assets/imgs/asteroids_explainer.svg";
import orbitingAsteroids1 from "./assets/imgs/orbiting_asteroids_1.svg";
import orbitingAsteroids2 from "./assets/imgs/orbiting_asteroids_2.svg";

const srcs = [
  redAsteroid,
  greenAsteroid,
  goldAsteroid,
  asteroidExplosion,
  asteroidsExplainer,
  orbitingAsteroids1,
  orbitingAsteroids2,
];

function GamePreload() {
  return (
    <>
      {srcs.map((src, i) => {
        return <link key={i} rel="preload" href={src} as="image"></link>;
      })}
    </>
  );
}

export default GamePreload;
