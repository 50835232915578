import styled from "styled-components/macro";
import { useParams } from "react-router-dom";
import AdminGroup from "./AdminGroup";
import { get, getDatabase, ref, set } from "firebase/database";
import { useEffect, useState, useMemo } from "react";
import { btnIcons } from "../shared-styles";
import Loading from "../Loading";
import EditHeader from "./EditHeader";
import AppHeader from "../AppHeader";
import ItemActions from "./ItemActions";
import { deleteFolder, renameFolder } from "./adminUtils";
import SearchInput from "../SearchInput";
import SelectOrEditButton from "./SelectOrEditButton";

const db = getDatabase();
// edit folder sets
async function fetchFolderSets(folderId: string) {
  const dbRef = ref(db, `folderSets/${folderId}`);
  return get(dbRef).then((ss) => ss.val() as { [key: string]: boolean | null });
}
async function setFolderSets(
  folderId: string,
  folderSets: { [key: string]: boolean | null }
) {
  const dbRef = ref(db, `folderSets/${folderId}`);
  return set(dbRef, folderSets);
}

const EditFolder = () => {
  const [search, setSearch] = useState("");
  const { id: folderId } = useParams();
  const [loadingParts, setLoadingParts] = useState({
    folderSets: true,
    sets: true,
  });
  const [sets, setSets] = useState<{ [key: string]: boolean | null }>({});
  const [hasChanged, setHasChanged] = useState(false);

  useEffect(() => {
    if (!folderId) console.error("no id");
    fetchFolderSets(folderId!).then((sets) => {
      setSets(sets ?? {});
      setLoadingParts((parts) => ({
        ...parts,
        folderSets: false,
      }));
    });
  }, [folderId]);

  const groups = useMemo(
    () => ({
      sets: {
        entityName: "set",
        itemBuilder: (id: string) =>
          <SelectOrEditButton
            key={id}
            type={"set"}
            id={id}
            onClick={() => {
              setHasChanged(true);
              setSets((sets) => ({
                ...sets,
                [id]: !sets[id] || null,
              }));
            }}
            selected={!!sets?.[id]}
          ></SelectOrEditButton>,
        onLoaded: () => setLoadingParts((parts) => ({ ...parts, sets: false })),
        idFilter: search,
        grouping: (id: string) => (!!sets?.[id] ? "in" : "out"),
      },
    }),
    [search, sets]
  );

  async function save() {
    setFolderSets(folderId!, sets).then(() => {
      setHasChanged(false);
    });
  }

  const isLoading = Object.values(loadingParts).some((v) => v);

  return (
    <StyledAdmin>
      <AppHeader>
        <Loading isLoading={isLoading}>
          <EditHeader
            save={save}
            saveDisabled={!hasChanged}
            icon={btnIcons.folder}
            title={folderId!}
            backTo={"/admin"}
          ></EditHeader>
          <ItemActions
            id={folderId!}
            itemType="folder"
            rename={(from, to) =>
              renameFolder(from, to).then(() => setHasChanged(false))
            }
            deleteItem={deleteFolder}
          />
          <SearchInput onSearchValue={setSearch} />
          <AdminGroup {...groups.sets}></AdminGroup>
        </Loading>
      </AppHeader>
    </StyledAdmin>
  );
};

export default EditFolder;

const StyledAdmin = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 400px;
  margin: auto;

  .groups.loading {
    visibility: hidden;
  }
`;
