import styled from "styled-components/macro";
import { ItemData } from "./Admin/Set/EditSet";
import { getPromptImgUrl } from "./utils";
import starryBackground from "./TheGame/assets/imgs/starry_background.svg";
import { useMemo } from "react";

export function PresentationSetItem({ id, e, c, i, }: ItemData) {
  const previewImg = useMemo(() => {
    return i ? (
      <img
        // required to force refetching image when it is changed
        src={getPromptImgUrl(id) + `?t=${Date.now()}`}
        title="prompt"
        alt="prompt"
      ></img>
    ) : (
      <></>
    );
  }, [i, id]);

  return (
    <StyledPresentationSetItem>
      <div className="item-label">{ }</div>
      <div className="item-container">
        <div className="col1">
          {c && <div className="chinese">
            {/* <label htmlFor="item-chinese">C</label> */}
            <textarea readOnly id="item-chinese">{c}</textarea>
          </div>}
          <div className="english">
            {/* <label htmlFor="item-english">E</label> */}
            <textarea readOnly id="item-english">{e}</textarea>
          </div>
        </div>
        {i && <div className="col2">
          {i ? previewImg : <></>}
        </div>}
      </div>
    </StyledPresentationSetItem>
  );
}

const StyledPresentationSetItem = styled.div`
  flex: 1;
  .item-container {
    height: 100%;
    display: flex;
    justify-content: start;
    background-color: rgb(182 175 255);
    margin-block: 5px;
    border-radius: 5px;
    padding: 5px 10px;
  }

  .col1 {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-right: 5px;
    align-items: flex-end;

    .english,
    .chinese {
      width: 100%;
      display: flex;
      flex: 1;
      align-items: center;

      textarea {
        outline: none;
    
        resize: none;
        cursor: initial;
        width: 0px;
        flex: 1;
        padding-left: 10px;
        padding-right: 10px;
        height: 100%;
        border-radius: 15px;    
        align-content: center;
        text-align: center;
      }
    }

    .chinese {
      margin-bottom: 2px;
    }
    .english {
      margin-top: 2px;
    }

    .english textarea {
      font-size: 0.85rem;
    }
  }
  .col1 > div {
    display: flex;
  }
  .col1 label {
    /* margin-left: 10px; */
  }

  .col1 input {
    flex: 1;
  }
  .col2 {
    margin: auto;
    width: 100px;
    height: 100px;
    border: dotted 1px white;
    border-radius: 5px;
    background-image: url(${starryBackground});
    display: flex;
    align-items: center;
    justify-content: center;

    .delete-img-btn-container {
      position: absolute;
      width: inherit;
      height: inherit;
      .delete-img-btn {
        position: absolute;
        top: 0;
        right: 0;
        transform: translateX(33%) translateY(-33%);
      }
    }
  }
  .col2 img {
    object-fit: contain;
    width: 100px;
    max-height: 100px;
  }
`;
