import { User } from "firebase/auth";

export function getNameFromEmail(user: User) {
    return user!.email!.match(/^(.*)@newworldenglish.com$/)![1]
}

export function getDisplayTime(milliseconds: number) {
    return `${(milliseconds / 1000).toFixed(1)}s`;
}

export function shuffleArray(array: any[]) {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
}

export function getPromptImgUrl(id: string) {
    return `https://storage.googleapis.com/new-world-english.appspot.com/images/${id}_200x200`
}

export function trimVal(val: string) {
    return val.replaceAll(/\([^)]*\)/g, '').replaceAll(/^[\s\p{P}\p{S}]+|[\s\p{P}\p{S}]+$/gu, "")
}
