import styled from "styled-components/macro";
import { useState } from "react";
import Modal from "../TheGame/Modal";
import Button from "../TheGame/Button";
import { btnColors, btnIcons } from "../shared-styles";
import ModalChild from "../TheGame/ModalChild";
import { DeleteItemButtonProps } from "./DeleteItemButton";
import { useNavigate } from "react-router-dom";
import SvgIcon from "@mui/material/SvgIcon";

interface DeleteItemModalProps extends DeleteItemButtonProps {
  cancel: () => void;
}

function DeleteItemModal(props: DeleteItemModalProps) {
  const navigate = useNavigate();
  const { itemType, cancel, id, submit } = props;
  const [isSubmitting, setIsSubmitting] = useState(false);

  async function onSubmit() {
    setIsSubmitting(true);
    await submit();
    navigate(`./../../`, { replace: true });
  }

  return (
    <StyledDeleteItemModal>
      <Modal dismiss={isSubmitting ? undefined : cancel}>
        <h1>Delete {itemType}</h1>
        <div className="spacer"></div>
        <ModalChild color="red">
          <div className="item-details">
            {
              <SvgIcon
                className="icon"
                component={btnIcons[itemType]}
              ></SvgIcon>
            }
            {id}
          </div>
        </ModalChild>
        <div className="spacer"></div>
        <Button
          width={200}
          buttonText="Delete"
          color={btnColors.delete}
          className="item-delete"
          onClick={() => onSubmit()}
        ></Button>
        {
          <button
            className={`cancel ${isSubmitting ? "hide" : ""}`}
            onClick={cancel}
          >
            Cancel
          </button>
        }
      </Modal>
    </StyledDeleteItemModal>
  );
}

export default DeleteItemModal;

const StyledDeleteItemModal = styled.div`
  .item-details {
    display: flex;
    align-items: center;

    .icon {
      margin-right: 10px;
    }
  }
  .item-delete {
    margin-top: 30px;
  }

  .delete-warning {
    text-transform: none;
    font-size: 0.6rem;
    margin-top: 5px;
  }

  button.cancel {
    margin-top: 20px;

    &.hide {
      visibility: hidden;
    }
  }

  .spacer {
    flex: 1;
  }
`;
