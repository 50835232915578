import { Auth } from "firebase/auth";
import { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";

function useIsAdmin(auth: Auth) {
    const [user] = useAuthState(auth);
    const [isAdmin, setIsAdmin] = useState<boolean | undefined>(undefined);

    useEffect(() => {
        if (user)
            user
                .getIdTokenResult()
                .then((idTokenResult) => {
                    // uids added to admins in the db have an admin claim added to auth via a cloud function.
                    if (!!idTokenResult.claims.admin) {
                        setIsAdmin(true);
                    } else {
                        setIsAdmin(false);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
    }, [user]);

    return isAdmin;
}

export default useIsAdmin;