import styled from "styled-components/macro";
import RocketIcon from "@mui/icons-material/Rocket";
import BackButton from "./BackButton";
interface Props {
  backTo?: string;
  isReady?: boolean;
  hasLaunched?: boolean;
}
const HomeHeader = ({
  backTo,
  isReady = false,
  hasLaunched = false,
}: Props) => {
  return (
    <StyledHomeHeader>
      <div className="header">
        <span className={`flex back-btn-container ${backTo ? "" : "hide"}`}>
          <BackButton to={backTo} />
        </span>
        <h1 className={`header-title ${hasLaunched ? "launch" : ""}`}>
          <RocketIcon
            className={`title-icon ${isReady ? "ready" : ""} ${
              hasLaunched ? "launch" : ""
            }`}
          />
          <div className="header-title-text">Mission Control</div>
          <RocketIcon
            className={`title-icon ${isReady ? "ready" : ""} ${
              hasLaunched ? "launch" : ""
            }`}
          />
        </h1>
        <span className="flex"></span>
      </div>
    </StyledHomeHeader>
  );
};

export default HomeHeader;

const StyledHomeHeader = styled.div`
  .header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    .flex {
      flex: 1;
    }

    .back-btn-container {
      display: flex;
    }

    .hide {
      visibility: hidden;
    }

    .title-icon {
      margin-right: 10px;
      margin-left: 10px;
    }

    .title-icon.ready {
      animation: shake 0.5s infinite linear;
    }

    .header-title {
      display: flex;
      align-items: center;
    }

    .header-title.launch {
      animation: shake 0.5s infinite linear;
      .title-icon {
        @keyframes launch {
          0% {
            transform: translateY(0px);
          }
          100% {
            transform: translateY(-200px);
          }
        }
        animation: launch 0.5s cubic-bezier(1, -0.25, 1, 1) forwards;
      }
    }

    .header-title {
      margin-left: 50px;
      margin-right: 50px;
    }
  }
`;
