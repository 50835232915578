import styled from "styled-components/macro";
import { getDatabase, ref, set, get } from "firebase/database";
import { useState, useEffect } from "react";
import { SetItem } from "./SetItem";
import { AddSetItem } from "./AddSetItem";
import Loading from "../../Loading";
import EditHeader from "../EditHeader";
import { btnIcons, colors, niceColors, btnColors } from "../../shared-styles";
import AppHeader from "../../AppHeader";
import { SmallButton } from "../../TheGame/Button";

import CloseIcon from "@mui/icons-material/Close";
import { deleteImage } from "../adminUtils";

const db = getDatabase();

export interface ItemData {
  id: string;
  e: string;
  c: string;
  i?: boolean;
  t?: number;
}

function EditPowerups() {
  const [isLoading, setIsLoading] = useState(true);
  const [initialItems, setInitialItems] = useState<Record<string, ItemData>>(
    {}
  );
  const [items, setItems] = useState<ItemData[]>([]);
  const [hasUpdated, setHasUpdated] = useState(false);

  useEffect(() => {
    const dbRef = ref(db, `powerupItems`);
    get(dbRef).then(
      (snapshot) => {
        const val = (snapshot.val() ?? {}) as { [id: string]: ItemData };
        const newItems = Object.entries(val)
          .map(([id, data]) => ({
            ...data,
            id,
          }))
          .sort((a, b) => (a.t === b.t ? a.e.localeCompare(b.e) : (a.t ?? 0) - (b.t ?? 0)));
        setInitialItems(val);
        setItems(newItems);
        setIsLoading(false);
      },
      (error) => console.error(error)
    );
  }, []);

  function onUpdate(newItem: ItemData) {
    setHasUpdated(true);
    setItems((items) => {
      const newItems = items.map((item) =>
        item.id !== newItem.id ? item : newItem
      );
      return newItems;
    });
  }

  function onAdd(item: ItemData) {
    setHasUpdated(true);
    setItems((items) => [...items, item]);
  }

  async function save(newItems?: ItemData[]) {
    const saveObj = Object.fromEntries(
      (newItems ?? items).map(({ id, c, e, i, t }) => [id, { c, e, i: i || null, t: t || null }])
    );
    const dbRef = ref(db, `powerupItems`);
    return set(dbRef, saveObj)
      .then(() => {
        const imgsToDelete = Object.keys(initialItems).filter((id) => {
          return initialItems[id].i && !saveObj[id]?.i;
        });
        if (imgsToDelete.length) {
          return Promise.all(imgsToDelete.map(deleteImage)).then((_) =>
            console.log("deleted images: ", imgsToDelete)
          );
        }
      })
      .finally(() => {
        setHasUpdated(false);
      })
      .catch((error) => console.error(error));
  }

  if (isLoading) return <Loading></Loading>;

  return (
    <StyledEditPowerups>
      <AppHeader>
        <EditHeader
          save={save}
          saveDisabled={!hasUpdated}
          icon={btnIcons.powerups}
          title="Powerup Items"
          backTo={"/admin"}
        ></EditHeader>
        <AddSetItem onAdd={onAdd} />
        <div className="set-items">
          {items.map((item, index) => (
            <div className="item-wrapper" key={item.id}>
              <div className="item-wrapper-label">
                <SmallButton
                  color={btnColors.delete}
                  buttonText={<CloseIcon />}
                  onClick={() => {
                    setItems((items) => items.filter((x) => x.id !== item.id));
                    setHasUpdated(true);
                  }}
                  className="remove-item-btn"
                />
                <div className="item-number">{index + 1}.</div>
              </div>
              <SetItem onUpdate={onUpdate} {...item} />
            </div>
          ))}
        </div>
      </AppHeader>
    </StyledEditPowerups>
  );
}

export default EditPowerups;

const StyledEditPowerups = styled.div`
  .edit-set-title {
    font-size: 2.5rem;
    font-weight: bold;
  }

  .edit-set-subtitle {
    font-size: 1.5rem;
    font-weight: bold;
  }

  .upload-actions {
    display: flex;
  }

  .set-items {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(375px, 1fr));

    .item-wrapper {
      display: flex;
      /* flex: 1 1 375px; */
      height: 120px;
      margin-block: 5px;

      .item-wrapper-label {
        width: 50px;
        position: relative;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        .remove-item-btn {
          position: absolute;
          right: 0;
          top: 3px;
        }
      }
    }
  }

  .set-items input,
  .add-inputs input {
    font-size: 1rem;
    padding-block: 0.1rem;
    margin-block: 0.4rem;

    &#item-chinese {
      background-color: ${colors.asteroid};
      color: ${niceColors.darkPurple};
    }

    &#item-english {
      background-color: ${niceColors.darkPurple};
    }
  }

  .app-content-container {
    width: 100%;

    .app-content {
      justify-content: center;
      align-items: center;

      .edit-header {
        width: min(900px, 100%);
      }
    }
  }
`;
