import styled from "styled-components/macro";
import { SmallButton } from "../../TheGame/Button";
import RocketIcon from "@mui/icons-material/Rocket";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { ref, get, getDatabase } from "firebase/database";
import EditHeader from "../EditHeader";
import AppHeader from "../../AppHeader";
import Loading from "../../Loading";
import TableRowsIcon from "@mui/icons-material/TableRows";
import StarIcon from "@mui/icons-material/Star";
import { niceColors } from "../../shared-styles";

const db = getDatabase();

function arrayToCsv(data: (string | number)[][]) {
  return data
    .map(
      (row) =>
        row
          .map(String) // convert every value to String
          .map((v) => v.replaceAll('"', '""')) // escape double colons
          .map((v) => `"${v}"`) // quote it
          .join(",") // comma-separated
    )
    .join("\r\n"); // rows starting on new lines
}

function downloadBlob(content: string, filename: string) {
  // Create a blob
  const blob = new Blob([content], { type: "text/csv;charset=utf-8;" });
  const url = URL.createObjectURL(blob);

  // Create a link to download it
  const a = document.createElement("a");
  a.href = url;
  a.setAttribute("download", filename);
  a.click();
}

async function exportHighscores(
  classId: string,
  setId: string,
  scoresByPeriod: Record<string, [string, number][]>
) {
  const groupRows = Object.entries(scoresByPeriod).map(([title, scores]) => {
    return [
      // header
      [title, ""],
      // subheader
      ["Username", "Score"],
      // scores
      ...scores.sort(([_, x], [__, y]) => y - x),
    ];
  });

  const numScores = Math.max(
    ...Object.values(scoresByPeriod).map((s) => Object.keys(s).length)
  );

  // init column with rankings
  let allRows: (string | number)[][] = [
    [""],
    [""],
    ...Array(numScores)
      .fill(null)
      .map((_, i) => [`${i + 1}.`]),
  ];

  const numRows = numScores + 2;

  groupRows.forEach((rows) => {
    for (let rIndex = 0; rIndex < numRows; rIndex++) {
      allRows[rIndex] = [
        ...(allRows[rIndex] ?? []),
        ...(rows[rIndex] ?? ["", ""]),
        "",
      ];
    }
  });
  const csvString = arrayToCsv(allRows);
  downloadBlob(
    csvString,
    `${classId} | ${setId} Highscores ${new Date()
      .toISOString()
      .substring(0, 10)}).csv`
  );
}

function ClassSetHighscores() {
  const { classId, setId } = useParams();
  const [scores, setScores] = useState<Record<
    string,
    [string, number][]
  > | null>(null);

  useEffect(() => {
    const promises = [
      // users
      get(ref(db, `classUsers/${classId}`)).then((snapshot) => {
        return Object.keys((snapshot.val() as Record<string, boolean>) ?? {});
      }),
      // weekly
      get(ref(db, `weeklyHighscores/${setId}`)).then((snapshot) => {
        return Object.entries((snapshot.val() as Record<string, number>) ?? {});
      }),
      // monthly
      get(ref(db, `monthlyHighscores/${setId}`)).then((snapshot) => {
        return Object.entries((snapshot.val() as Record<string, number>) ?? {});
      }),
      // all
      get(ref(db, `highscores/${setId}`)).then((snapshot) => {
        return Object.entries((snapshot.val() as Record<string, number>) ?? {});
      }),
    ];

    Promise.all(promises).then((result) => {
      const users = result[0] as string[];
      const weekly = result[1] as [string, number][];
      const monthly = result[2] as [string, number][];
      const allTime = result[3] as [string, number][];

      setScores({
        Weekly: weekly.filter(([u]) => users.includes(u)),
        Monthly: monthly.filter(([u]) => users.includes(u)),
        "All Time": allTime.filter(([u]) => users.includes(u)),
      });
    });
  }, [classId, setId]);

  function buildHighscoreEntry(index: number, name: string, score: number) {
    return (
      <div key={index} className="highscore">
        <div className="highscore-name">{name}</div>
        <div className="highscore-score">{score.toLocaleString()}</div>
      </div>
    );
  }

  return (
    <StyledClassSetHighscores>
      <AppHeader>
        <Loading isLoading={!scores}>
          <EditHeader
            icon={StarIcon}
            title={
              <>
                <div className="title-class">
                  {classId!}
                  <div className="title-set">{setId}</div>
                </div>
                {scores && (
                  <SmallButton
                    className="export-btn"
                    icon={TableRowsIcon}
                    buttonText="Export .csv"
                    color="gold"
                    onClick={() => exportHighscores(classId!, setId!, scores)}
                  />
                )}
              </>
            }
            backTo={`/admin/class/${classId}`}
          ></EditHeader>
          {scores && (
            <div className="scores">
              {Object.entries(scores).map(([title, scores]) => {
                return (
                  <div className="highscore-group" key={title}>
                    <div className="highscore-group-icon">
                      <RocketIcon />
                      <h2 className="highscore-group-title">{title}</h2>
                      <RocketIcon />
                    </div>
                    <div className="highscores">
                      {scores.length === 0
                        ? "(no highscores)"
                        : scores.map(([user, score], index) => {
                            return buildHighscoreEntry(index, user, score);
                          })}
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </Loading>
      </AppHeader>
    </StyledClassSetHighscores>
  );
}

export default ClassSetHighscores;

const StyledClassSetHighscores = styled.div`
  .edit-header {
    position: relative;
  }

  .title-class {
    .title-set {
      position: absolute;
      font-size: 1rem;
      width: 100%;
      left: 0;
    }
  }

  .export-btn {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    display: flex;
    align-items: center;
  }

  .scores {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-block: 30px;

    .highscore-group {
      width: 250px;
      display: flex;
      flex-direction: column;
      align-items: center;
      background: ${niceColors.darkPurple};
      border-radius: 8px;
      padding-top: 10px;
      padding-bottom: 30px;
      margin-left: 10px;
      margin-right: 10px;
      margin-bottom: 10px;

      .highscore-group-icon {
        display: flex;
        align-items: center;
      }

      .highscore-group-title {
        margin-left: 10px;
        margin-right: 10px;
      }
    }
  }

  .highscore {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-left: 8px;
    margin-right: 8px;
    font-size: 0.9rem;

    .highscore-rank {
      width: 1.2rem;
      text-align: start;
    }

    .highscore-name {
      margin-left: 5px;
      margin-right: 20px;
      width: 130px;
      text-align: start;
      text-transform: none;
    }

    .highscore-score {
      color: yellow;
      width: 70px;
      text-align: end;
    }
  }
`;
