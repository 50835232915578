import { useEffect, useRef, useState } from "react";
import styled from "styled-components/macro";
import warpDrive from "./warpDrive.js";
import { niceColors } from "../shared-styles";

interface Props {
  on: boolean;
  isRunning: boolean;
  level: number;
}

function MyWarpDrive({ on, isRunning, level }: Props) {
  const rootEl = useRef<HTMLDivElement>(null);
  const [wd, setWd] = useState<any | null>(null);

  useEffect(() => {
    const wd = warpDrive(rootEl.current!, {
      starfieldBackgroundColor: niceColors.darkPurple,
    });

    const observer = new ResizeObserver(
      ([
        {
          contentRect: { width, height },
        },
      ]) => {
        wd.resize(width, height);
      }
    );
    observer.observe(rootEl.current!.parentElement!);

    setWd(wd);

    return () => {
      wd.destroy();
      observer.disconnect();
    };
  }, []);

  useEffect(() => {
    if (wd) on ? wd.speedUp(Math.min(level, 20) * 20) : wd.slowDown();
  }, [level, on, wd]);

  useEffect(() => {
    if (wd) isRunning ? wd.unpause() : wd.pause();
  }, [isRunning, wd]);

  return (
    <StyledMyWarpDrive
      className="my-warp-drive"
      ref={rootEl}
    ></StyledMyWarpDrive>
  );
}

const StyledMyWarpDrive = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: -100;

  canvas {
    width: 100%;
    height: 100%;
    display: block; /* this is IMPORTANT! */
  }
`;

export default MyWarpDrive;
