import styled from "styled-components/macro";
import { btnColors, launchPanel } from "./shared-styles";
import LaunchIndicator from "./LaunchIndicator";
import Button from "./TheGame/Button";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import { useNavigate } from "react-router-dom";

interface Props {
  readyTypes: string[];
  setId?: string;
  slideIn?: boolean;
  handleLaunch?: () => Promise<void>;
}

const LaunchPanel = (props: Props) => {
  const { readyTypes, setId, handleLaunch } = props;

  const navigate = useNavigate();
  return (
    <StyledLaunchPanel {...props}>
      <LaunchIndicator readyTypes={readyTypes} />
      <div className="launch-btn-wrapper">
        {!!setId && <div className="launch-btn-glow"></div>}
        <Button
          className="gravity-btn"
          disabled={!setId}
          icon={RocketLaunchIcon} 
          onClick={async () => {
            await handleLaunch?.();
            navigate(`/mission-select/${setId}`);
          }}
          buttonText={`Launch`}
          color={btnColors.launch}
        ></Button>
      </div>
    </StyledLaunchPanel>
  );
};

export default LaunchPanel;

const StyledLaunchPanel = styled.div<Props>`
  animation: ${({ setId }) => (setId ? "shake 0.5s infinite" : "none")};
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${launchPanel.color};
  height: ${launchPanel.height};
  border-radius: 5px;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;

  .launch-btn-wrapper {
    position: relative;
    margin-left: 30px;
    padding: 10px;
    border-radius: 5px;
    background-color: rgb(0, 0, 50);

    .launch-btn-glow {
      position: absolute;
      height: 150%;
      width: 150%;
      transform: translateX(-20%) translateY(-22%);
      background: radial-gradient(
        ${btnColors.launch},
        rgba(255, 255, 255, 0) 60%
      );
    }
  }
`;
