import styled from "styled-components/macro";
import { useState } from "react";
import Modal from "../TheGame/Modal";
import Button from "../TheGame/Button";
import { btnColors, btnIcons } from "../shared-styles";
import ModalChild from "../TheGame/ModalChild";
import { DuplicateSetButtonProps } from "./DuplicateSetButton";
import { useNavigate } from "react-router-dom";
import SvgIcon from "@mui/material/SvgIcon";
import { duplicateSet } from "./adminUtils";

interface DuplicateSetModalProps extends DuplicateSetButtonProps {
  cancel: () => void;
}

interface UploadProgress {
  uploaded: number;
  total: number;
}

function DuplicateSetModal(props: DuplicateSetModalProps) {
  const navigate = useNavigate();
  const { cancel, id } = props;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [uploadProgress, setUploadProgress] = useState<UploadProgress | null>(
    null
  );

  async function onSubmit() {
    setIsSubmitting(true);
    const newId = await duplicateSet(id, (uploaded, totalFiles) => {
      setUploadProgress({
        uploaded: Object.keys(uploaded).length,
        total: totalFiles,
      });
    });
    navigate(`./../${newId}`);
    cancel();
  }

  return (
    <StyledDuplicateSetModal>
      <Modal dismiss={isSubmitting ? undefined : cancel}>
        <h1>Duplicate Set</h1>
        <div className="spacer"></div>
        <ModalChild color="red">
          <div className="item-details">
            {<SvgIcon className="icon" component={btnIcons.set}></SvgIcon>}
            {id}
          </div>
        </ModalChild>
        <div className="spacer"></div>
        <Button
          width={200}
          buttonText="Duplicate"
          color={btnColors.duplicate}
          className="item-duplicate"
          onClick={() => onSubmit()}
          disabled={isSubmitting}
        ></Button>
        <div
          className={`warning duplicate-warning ${
            isSubmitting && !uploadProgress ? "hide" : ""
          }`}
        >
          {uploadProgress
            ? `Copying files: ${uploadProgress.uploaded}/${uploadProgress.total}`
            : "Duplicating will lose any unsaved edits."}
        </div>
        {
          <button
            className={`cancel ${isSubmitting ? "hide" : ""}`}
            onClick={cancel}
          >
            Cancel
          </button>
        }
      </Modal>
    </StyledDuplicateSetModal>
  );
}

export default DuplicateSetModal;

const StyledDuplicateSetModal = styled.div`
  .item-details {
    display: flex;
    align-items: center;

    .icon {
      margin-right: 10px;
    }
  }
  .item-duplicate {
    margin-top: 30px;
  }

  .duplicate-warning {
    text-transform: none;
    font-size: 0.6rem;
    margin-top: 5px;
  }

  button.cancel {
    margin-top: 20px;
  }

  .hide {
    visibility: hidden;
  }

  .spacer {
    flex: 1;
  }
`;
