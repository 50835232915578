import styled from "styled-components/macro";
import { useEffect, useState } from "react";
import { useSignInWithEmailAndPassword } from "react-firebase-hooks/auth";
import { auth } from "./firebase";
import RocketIcon from "@mui/icons-material/Rocket";
import { launchPanel } from "./shared-styles";
import CloseIcon from "@mui/icons-material/Close";
import OrbitingAsteroids from "./TheGame/OrbitingAsteroids";
import ModalChild from "./TheGame/ModalChild";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import Button, { SmallButton } from "./TheGame/Button";
import SlideDown from "./SlideDown";

interface Props {
  loginTitle?: string;
  loginText?: string;
}

const LoginContent = ({ loginTitle, loginText }: Props) => {
  const [hasErrored, setHasErrored] = useState(false);
  const [inputVal, setInputVal] = useState("");
  const [passwordVal, setPasswordVal] = useState("");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [signInWithEmailAndPassword, user, loading, error] =
    useSignInWithEmailAndPassword(auth);
  const [showPasswordField, setShowPasswordField] = useState(false);

  const onSubmit = (e?: React.FormEvent<HTMLFormElement>) => {
    e?.preventDefault();
    const username = inputVal.toLowerCase();
    signInWithEmailAndPassword(
      `${username}@newworldenglish.com`,
      passwordVal || `${username}password`
    );
  };

  useEffect(() => {
    if (error) {
      console.error(error.code);
      setHasErrored(true);
      const timeout = window.setTimeout(() => setHasErrored(false), 1000);
      return () => {
        setHasErrored(false);
        window.clearTimeout(timeout);
      };
    }
  }, [error]);

  return (
    <StyledLoginContent>
      <SlideDown>
        <div className="title">{loginTitle?.toLocaleUpperCase() ?? "log in"}</div>
      </SlideDown>
      <ModalChild className="login-form">
        <div className="graphic">
          <OrbitingAsteroids />
          <div className="option-admin">
            <SmallButton
              buttonText="Admin"
              selected={showPasswordField}
              color="orange"
              icon={
                showPasswordField ? CheckBoxIcon : CheckBoxOutlineBlankIcon
              }
              onClick={() => {
                setShowPasswordField((p) => !p);
              }}
            />
          </div>
        </div>
        <form className="username-input-form" onSubmit={onSubmit}>
          <input
            autoFocus
            autoComplete="false"
            type="text"
            placeholder="ENTER USERNAME"
            title="username input"
            className="username-input"
            onChange={(el) => setInputVal(el.target.value)}
          />
          {showPasswordField && (
            <input
              autoComplete="false"
              type="password"
              placeholder="ENTER PASSWORD"
              title="password input"
              className="password-input"
              onChange={(el) => setPasswordVal(el.target.value)}
            />
          )}
          <div className="msg">
            {(loading || hasErrored) && <RocketIcon className="loading" />}
            {hasErrored && <CloseIcon className="error" />}
          </div>
        </form>
      </ModalChild>
      <div className="login-btn-container">
        <Button
          color="orange"
          disabled={!inputVal}
          selected={loading}
          className="login-btn"
          onClick={onSubmit}
          buttonText={loginText?.toLocaleUpperCase() ?? "MISSION CONTROL"}
          icon={RocketIcon}
        />
      </div>
    </StyledLoginContent >
  );
};

export default LoginContent;

const StyledLoginContent = styled.div`
  margin-top: 40px;
  margin-bottom: 130px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;

  .msg-container {
    height: 50px;
    display: flex;
    flex-direction: column;
  }

  .app-content {
    flex: 1;
  }

  .login-btn {
    /* margin-top: 30px; */
  }

  .title {
    z-index: -1;
    font-size: 4rem;
    font-weight: bold;
    flex: 1;
    display: flex;
    align-items: flex-end;
    margin-bottom: 20px;
    justify-content: center;
  }

  .login-form {
    @keyframes show-login {
      0% {
        transform: scale(0);
      }
      80% {
        transform: scale(1.05);
      }
      100% {
        transform: scale(1);
      }
    }

    transform: scale(0);
    flex: 1;
    animation: show-login 0.2s ease forwards;
  }

  .graphic {
    margin-bottom: 30px;
    margin-top: 10px;
    display: flex;
    justify-content: center;
    position: relative;

    .container {
      height: 130px;
    }
  }

  .option-admin {
    position: absolute;
    display: flex;
    justify-content: flex-end;
    right: 0px;
    bottom: 0;
    transform: translateY(50%);
  }

  .username-input-form {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-left: 30px;
    padding-right: 30px;

    .password-input {
      margin-top: 20px;
    }

    @keyframes scale-in {
      0% {
        transform: scale(0);
      }
      60% {
        transform: scale(1.1);
      }
      100% {
        transform: scale(1);
      }
    }
    animation: scale-in 0.3s forwards;
  }

  .msg {
    height: 100%;
    display: flex;
    align-items: center;
    position: absolute;
    right: 0px;

    .loading {
      @keyframes loading {
        0% {
          transform: rotate(0deg);
        }
        25% {
          transform: rotate(8deg);
        }
        75% {
          transform: rotate(-8deg);
        }
        100% {
          transform: rotate(0deg);
        }
      }
      animation: loading 0.15s infinite linear;
    }

    .error {
      color: red;
      position: absolute;
    }
  }

  .spacer {
    flex: 1;
  }

  @keyframes slide-up {
    0% {
      transform: translateY(100%);
    }
    100% {
      transform: translateY(0%);
    }
  }

  .login-btn-container {
    animation: slide-up 1s forwards ease;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${launchPanel.color};
    height: ${launchPanel.height};
    padding-block: 40px;
      border-radius: 5px;
  }
`;
