import styled from "styled-components/macro";
import { ItemData } from "./EditSet";
import { UploadImgButton } from "./UploadImgButton";
import starryBackground from "../../TheGame/assets/imgs/starry_background.svg";
import { getPromptImgUrl } from "../../utils";
import { useMemo } from "react";
import { SmallButton } from "../../TheGame/Button";
import CloseIcon from "@mui/icons-material/Close";

interface Props extends ItemData {
  setId?: string;
  onUpdate: (data: ItemData) => void;
}

export function SetItem({ setId, id, e, c, i, t, onUpdate }: Props) {
  function onChange(newData: Partial<ItemData>) {
    const data = { id, e, c, i, t, ...newData };
    onUpdate(data);
  }

  const deleteImgBtn = (
    <SmallButton
      className="delete-img-btn"
      width={20}
      height={20}
      depth={2}
      color="orange"
      buttonText={<CloseIcon />}
      onClick={() => {
        onChange({
          i: undefined,
        });
      }}
    ></SmallButton>
  );

  const uploadBtn = (
    <UploadImgButton
      id={id}
      setId={setId}
      onUpload={() => onUpdate({ id, e, c, i: true })}
      buttonText={
        <>
          Upload
          <br />
          Image
        </>
      }
      height={i ? 25 : 75}
    ></UploadImgButton>
  );

  const previewImg = useMemo(() => {
    return i ? (
      <img
        // required to force refetching image when it is changed
        src={getPromptImgUrl(id) + `?t=${Date.now()}`}
        title="prompt"
        alt="prompt"
      ></img>
    ) : (
      <></>
    );
  }, [i, id]);

  return (
    <StyledSetItem>
      <div className="item-label">{}</div>
      <div className="item-container">
        <div className="col1">
          <div className="chinese">
            {/* <label htmlFor="item-chinese">C</label> */}
            <input
              type="text"
              id="item-chinese"
              placeholder="[中文]"
              value={c}
              onChange={({ target }) => {
                onChange({ c: target.value });
              }}
            />
          </div>
          <div className="english">
            {/* <label htmlFor="item-english">E</label> */}
            <input
              type="text"
              id="item-english"
              placeholder="[ENGLISH]"
              value={e}
              onChange={({ target }) => {
                onChange({ e: target.value });
              }}
            />
          </div>
          <div className="spacer"></div>
        </div>
        <div className="col2">
          {i ? previewImg : uploadBtn}
          {!!i && (
            <div className="delete-img-btn-container">{deleteImgBtn}</div>
          )}
        </div>
      </div>
    </StyledSetItem>
  );
}

const StyledSetItem = styled.div`
  flex: 1;
  .item-container {
    height: 100%;
    display: flex;
    justify-content: start;
    background-color: rgb(182 175 255);
    margin-block: 5px;
    border-radius: 5px;
    padding: 5px 10px;
  }

  .col1 {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-right: 5px;
    align-items: flex-end;

    .english,
    .chinese {
      width: 100%;
      display: flex;
      align-items: center;

      input {
        margin-left: 15px;
        margin-right: 10px;
        width: 0px;
        flex: 1;
      }
    }
  }
  .col1 > div {
    display: flex;
  }
  .col1 label {
    /* margin-left: 10px; */
  }

  .col1 input {
    flex: 1;
  }
  .spacer {
    flex: 1;
  }
  .col2 {
    margin: auto;
    width: 100px;
    height: 100px;
    border: dotted 1px white;
    border-radius: 5px;
    background-image: url(${starryBackground});
    display: flex;
    align-items: center;
    justify-content: center;

    .delete-img-btn-container {
      position: absolute;
      width: inherit;
      height: inherit;
      .delete-img-btn {
        position: absolute;
        top: 0;
        right: 0;
        transform: translateX(33%) translateY(-33%);
      }
    }
  }
  .col2 img {
    object-fit: contain;
    width: 100px;
    max-height: 100px;
  }
`;
