import { useEffect, useMemo, useState } from "react";
import styled from "styled-components/macro";
import {
  getPromptImgUrl,
} from "../utils";
import starryBackground from "../TheGame/assets/imgs/starry_background.svg";
import { btnColors, niceColors } from "../shared-styles";
import { useParams } from "react-router-dom";
// import { getAuth } from "firebase/auth";
// import { useAuthState } from "react-firebase-hooks/auth";
import AppHeader from '../AppHeader';
import PreviewIcon from '@mui/icons-material/Preview';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import CasinoIcon from '@mui/icons-material/Casino';
import {
  get,
  getDatabase,
  ref,
} from "firebase/database";
import { MediumButton } from "../TheGame/Button";
import SlideDown from "../SlideDown";
import Header from "../Header";

const db = getDatabase();
// const auth = getAuth();

interface IPromptData {
  e: string;
  c: string;
  i?: string;
}

function TheFlashcards({ demo = false }: { demo?: boolean }) {
  const { setId } = useParams();
  // const [user] = useAuthState(auth);
  const [setItems, setSetItems] = useState<IPromptData[] | null>(null);
  const [index, setIndex] = useState<number>(0);
  const [isClicked, setIsClicked] = useState(false);

  useEffect(() => {
    const dbRef = ref(db, `setItems/${setId}`);
    get(dbRef).then((snapshot) => {
      const val = snapshot.val();
      const set = Object.entries(val ?? {}) as [string, IPromptData][];
      if (set.length === 0) {
        throw new Error("set data not found");
      }
      const setItems: IPromptData[] = set.map(([id, data]) => ({
        ...data,
        i: data.i ? getPromptImgUrl(id) : undefined,
      }))
      setSetItems(setItems);
    });
  }, [demo, setId]);

  const { c, e, i } = useMemo(() => setItems?.[index] ?? { c: null, e: null, i: null }, [setItems, index]);

  return (
    <StyledTheFlashcards {...{ percentage: setItems ? (index * 100 / setItems.length) : 0 }}>
      <AppHeader>
        <div className="flashcards">
          <Header backTo="./../">{setId}</Header >
          <h2 className="flashcards-heading">
            <PreviewIcon />
            <div className="flashcards-title">Flashcards</div>
            <PreviewIcon />
          </h2>
          {setItems && <div className="flashcards-container">
            <div className="flashcards-top">
              <SlideDown key="0" delay={Math.random() * 0.15} duration={0.25}>
                <MediumButton
                  className="flashcards-back"
                  onClick={() => { setIndex((i) => (i + setItems.length - 1) % setItems.length); setIsClicked(false); }}
                  buttonText={<KeyboardArrowLeftIcon />}
                  color={btnColors.flashcards}
                ></MediumButton>
              </SlideDown>
              <SlideDown delay={Math.random() * 0.15} duration={0.25}>
                <div className="flashcards-row-title">{index + 1} / {setItems.length}</div>
              </SlideDown>
              <SlideDown delay={Math.random() * 0.15} duration={0.25}>
                <MediumButton
                  className="flashcards-forward"
                  onClick={() => { setIndex((i) => (i + 1) % setItems.length); setIsClicked(false); }}
                  buttonText={<KeyboardArrowRightIcon />}
                  color={btnColors.flashcards}
                ></MediumButton>
              </SlideDown>
              {setItems.length > 1 && <SlideDown delay={Math.random() * 0.15} duration={0.25}>
                <MediumButton
                  className="flashcards-random"
                  onClick={() => { setIndex(Math.floor(Math.random() * setItems.length)); setIsClicked(false); }}
                  buttonText={<CasinoIcon />}
                  color="#ff7d6b"
                ></MediumButton>
              </SlideDown>}
            </div>
            <div className="flashcards-main">
              {/* {JSON.stringify(setItems[index])} */}
              {i && <img className="prompt-img" src={i} alt="img"></img>}
              {c && <div className="prompt-c">{c}</div>}
              {e && <div className={`prompt-e ${isClicked ? "e-clicked" : ""}`}
                onClick={() => { setIsClicked(true) }}
              >
                <div className="prompt-e-text">{e}</div>
              </div>}
            </div>
          </div>}
        </div>
      </AppHeader>
      <footer className="footer">
      </footer>
    </StyledTheFlashcards>
  );
}

export default TheFlashcards;

const StyledTheFlashcards = styled.div<{ percentage: number }>`
  background-image: url(${starryBackground});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  color: white;

  .app-content {
    flex: 1;

    .flashcards {
      flex: 1;
      display: flex;
      flex-direction: column;
    }
  }

  .footer {
    width: ${p => p.percentage}%;
    transition: width 0.3s ease;
    height: 10px;
    background-color: ${btnColors.flashcards};
    position: absolute;
    bottom: 0;
  }

  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;

  .flashcards-heading {
    display: flex;
    flex-direction: row;
    text-align: center;
    align-items: center;
    justify-content: center;
    .flashcards-title {
      margin-right: 20px;
      margin-left: 20px;
    }
    @keyframes unblur {
      0% {
          filter: blur(10px);
      }
      100% {
          filter: none;
      }
    }

    animation: unblur 0.5s ease forwards;
  }
  .flashcards-top {
    display: flex;
    flex-direction: row;
    text-align: center;
    align-items: center;
    justify-content: center;
    .flashcards-row-title {
      margin-right: 20px;
      margin-left: 20px;
      width: 100px;
    }
  }


  .header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    .flex {
      flex: 1;
    }

    .back-btn-container {
      display: flex;
    }
  }

  .flashcards-container {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  .flashcards-main {
    text-transform: none;
    background-color: ${niceColors.darkDarkPurple};
    margin: 20px;
    border-radius: 20px;
    padding: 3vh 6vh;
    // flex: 1;

    > * {
      margin: 1vh 6vh;
    }

    .prompt-img {
      height: 27vh;
    }
    
    .prompt-c {
      font-size: 2rem;
    }
    
    .prompt-e {
      padding: 1.5vh;
      font-size: 2.5rem;
      border-style: dotted;
      border-width: 5px;
      border-color: ${btnColors.flashcards}40;
      cursor: pointer;
    }

    .e-clicked {
      cursor: initial;
      border-color: ${btnColors.flashcards};
      .prompt-e-text {
        visibility: visible;
        @keyframes swell {
          50% {
            transform: scale(1.1);
          }
          100% {
            transform: scale(1);
          }
        }
        animation: swell 0.4s ease;
      }
    }

    .prompt-e-text {
      visibility: hidden;
      cursor: default;
    }
  }
`;
