import styled from "styled-components/macro";
import { useParams } from "react-router-dom";
import { SmallButton } from "../TheGame/Button";
import AdminGroup from "./AdminGroup";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { get, getDatabase, ref, update } from "firebase/database";
import { useEffect, useState, useMemo } from "react";
import { btnColors, btnIcons } from "../shared-styles";
import Loading from "../Loading";
import EditHeader from "./EditHeader";
import AppHeader from "../AppHeader";
import ItemActions from "./ItemActions";
import { deleteUser, renameUser } from "./adminUtils";
import SearchInput from "../SearchInput";

const db = getDatabase();
// user classes
async function fetchUserClasses(userId: string) {
  const dbRef = ref(db, `userClasses/${userId}`);
  return get(dbRef).then((ss) => ss.val() as { [key: string]: boolean | null });
}

const EditUser = () => {
  const [search, setSearch] = useState("");
  const { id: userId } = useParams();
  const [loadingParts, setLoadingParts] = useState({
    userClasses: true,
    allClasses: true,
  });
  const [classes, setClasses] = useState<{ [key: string]: boolean | null }>({});
  const [classUsersUpdates, setClassUsersUpdates] = useState<{
    [key: string]: boolean | null;
  }>({});
  const [hasChanged, setHasChanged] = useState(false);

  useEffect(() => {
    if (!userId) console.error("no id");
    fetchUserClasses(userId!).then((userClassesDef) => {
      setClasses(userClassesDef ?? {});
      setLoadingParts((parts) => ({
        ...parts,
        userClasses: false,
      }));
    });
  }, [userId]);

  const itemBuilder = (
    type: string,
    id: string,
    selected: boolean,
    onClick: VoidFunction
  ) => {
    return (
      <SmallButton
        key={id}
        buttonText={id}
        onClick={onClick}
        icon={selected ? CheckBoxIcon : CheckBoxOutlineBlankIcon}
        color={btnColors[type]}
        selected={selected}
      ></SmallButton>
    );
  };

  const groups = useMemo(
    () => ({
      classes: {
        entityName: "class",
        itemBuilder: (id: string) =>
          itemBuilder("class", id, !!classes?.[id], () => {
            setHasChanged(true);
            const newValue = !classes[id] || null;
            setClasses((classes) => ({
              ...classes,
              [id]: newValue,
            }));
            setClassUsersUpdates((updates) => ({
              ...updates,
              [`classUsers/${id}/${userId}`]: newValue,
            }));
          }),
        onLoaded: () =>
          setLoadingParts((parts) => ({ ...parts, allClasses: false })),
        idFilter: search,
        grouping: (id: string) => (!!classes?.[id] ? "in" : "out"),
      },
    }),
    [classes, search, userId]
  );

  async function save() {
    const rootRef = ref(db);
    const updateObj = {
      [`userClasses/${userId!}`]: classes,
      ...classUsersUpdates,
    };
    update(rootRef, updateObj).then(() => {
      setHasChanged(false);
    });
  }

  const isLoading = Object.values(loadingParts).some((v) => v);

  return (
    <StyledAdmin>
      <AppHeader>
        <Loading isLoading={isLoading}>
          <EditHeader
            save={save}
            saveDisabled={!hasChanged}
            icon={btnIcons.user}
            title={userId!}
            backTo={"/admin"}
          ></EditHeader>
          <ItemActions
            id={userId!}
            itemType="user"
            rename={(from, to) =>
              renameUser(from, to).then(() => setHasChanged(false))
            }
            deleteItem={deleteUser}
          />
          <SearchInput onSearchValue={setSearch} />
          <AdminGroup {...groups.classes}></AdminGroup>
        </Loading>
      </AppHeader>
    </StyledAdmin>
  );
};

export default EditUser;

const StyledAdmin = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 400px;
  margin: auto;

  .groups.loading {
    visibility: hidden;
  }
`;
