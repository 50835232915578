import { useState } from "react";
import { btnColors, btnIcons } from "../shared-styles";
import { SmallButton } from "../TheGame/Button";
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import styled from "styled-components/macro";
import { useNavigate } from "react-router-dom";

export interface SelectOrEditButtonProps {
  type: string,
  id: string,
  selected: boolean,
  onClick: VoidFunction
  // onEdit: VoidFunction
}

function SelectOrEditButton({ type, id, selected, onClick }: SelectOrEditButtonProps) {
  const navigate = useNavigate();
  const [isHovered, setIsHovered] = useState(false);
  const [isEditHovered, setIsEditHovered] = useState(false);
  return (
    <StyledSaveButton
    >
      <SmallButton
        buttonText={id}
        onClick={onClick}
        icon={selected ? CheckBoxIcon : CheckBoxOutlineBlankIcon}
        color={btnColors[type]}
        selected={selected}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      ></SmallButton>
      {
        (isHovered || isEditHovered) &&
        <SmallButton
          height={20}
          depth={2}
          className={'select-edit-button'}
          buttonText={'Edit'}
          onClick={() => navigate(`./../../${type}/${id}`)}
          color={'white'}
          selected={false}
          icon={btnIcons.edit}
          onMouseEnter={() => setIsEditHovered(true) }
          onMouseLeave={() => setIsEditHovered(false)}
        ></SmallButton>}
    </StyledSaveButton >
  );
}

export default SelectOrEditButton;

const StyledSaveButton = styled.div`
position: relative;
  .select-edit-button {
      position: absolute;
      z-index: 1;
      right: -10px;
      top: -10px;
      animation: appear 0.2s ease;
  }

  @keyframes appear {
    0% {
      scale: 0;
    }
    100% {
      scale: 1;
    }
  }
`;

