import styled from "styled-components/macro";
import { useMemo, useState } from "react";
import Modal from "../TheGame/Modal";
import Button from "../TheGame/Button";
import InputIcon from "../InputIcon";
import { btnIcons } from "../shared-styles";
import ModalChild from "../TheGame/ModalChild";

interface AddItemModalProps {
  existingIds: string[];
  submit: (id: string) => void;
  cancel: VoidFunction;
  itemType: string;
}

function AddItemModal(props: AddItemModalProps) {
  const { itemType, cancel, submit, existingIds } = props;
  const [id, setId] = useState("");

  const hasBannedChars = useMemo(() => {
    return !!id.match(/[.#$[\]]/);
  }, [id]);
  return (
    <StyledAddItemModal>
      <Modal dismiss={cancel}>
        <h1>Add {itemType}</h1>
        <div className="spacer"></div>
        <ModalChild>
          <InputIcon icon={btnIcons[itemType]}>
            <input
              autoFocus
              className="id-input"
              type="text"
              title="id input"
              placeholder={`enter ${itemType} name`}
              onChange={({ target }) => setId(target.value)}
            />
          </InputIcon>
        </ModalChild>
        <div className="spacer"></div>
        <Button
          width={200}
          buttonText="Add"
          className="item-add"
          disabled={!id || existingIds.includes(id) || hasBannedChars}
          onClick={() => submit(id)}
        ></Button>
        <div
          className={`warning banned-characters ${
            hasBannedChars ? "show" : ""
          }`}
        >
          Name must not contain ".", "#", "$", "[", or "]"
        </div>
        <button className="cancel" onClick={cancel}>
          Cancel
        </button>
      </Modal>
    </StyledAddItemModal>
  );
}

export default AddItemModal;

const StyledAddItemModal = styled.div`
  .item-add {
    margin-top: 30px;
  }

  button.cancel {
    margin-top: 20px;
  }

  .spacer {
    flex: 1;
  }

  .banned-characters {
    text-transform: none;
    font-size: 0.6rem;
    margin-top: 5px;
    color: red;
    visibility: hidden;

    &.show {
      visibility: initial;
    }
  }
`;
