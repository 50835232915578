import { useEffect, useRef } from "react";
import styled from "styled-components/macro";

const MissionGuy = ({ color = '#f87e3c' }: { color: string | null, }) => {
	const guy = useRef<HTMLInputElement | null>(null);
	let theColor = color ?? '#CBC3E3';

	useEffect(() => {
		guy.current!.style.display = 'none';
		document.addEventListener('mousemove', function (e) {
			const posX = e.clientX;
			const posY = e.clientY;
			const delay = 100; // Adjust delay as needed

			// Move the guy with a slight delay
			setTimeout(() => {
				let s = guy.current?.style;
				if (s) {
					guy.current!.style.display = 'initial';
					s.left = posX + 'px';
					s.top = posY + 'px';
				}
			}, delay);
		});
	}, []);

	return (
		<StyledMissionGuy ref={guy} spin={color !== null} className="mission-guy">
			<div className="orbit-container">
				<div className="orbit">
					<div className="bob">
						<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24px" height="24px">
							<path fill={theColor}
								d="M6,20.631v-3.632c-0.651-1.071-1-1.947-1-3L4.999,9.595 c1.244,0.901,3.4,1.404,5.001,1.404c2.325,0,3-1.896,3-4c0-1.765-3.893-2-6-2c-0.476,0-0.881,0.063-1.224,0.173 C6.768,3.402,8.643,1.999,11,1.999c3.361,0,5,2.644,5,5v12c0,1.645-0.367,3-2.181,3C13.075,22,12,21.904,12,20.631v-1.632h-2 c0,1.645-0.367,3-2.181,3C7.075,22,6,21.904,6,20.631z" />
							<path fill="white"
								d="M 8.9781832,9.9612671 C 7.2423595,9.6996694 5.8769643,9.1651846 5.2229677,8.4912857 4.9484328,8.2083963 4.8969124,7.985757 4.9894506,7.4821691 5.1641175,6.5316409 5.5794688,6.1390543 6.5538268,6.0035335 c 0.516088,-0.071782 2.3602308,0.010177 3.2562975,0.1447148 1.1163607,0.1676154 2.1121697,0.5096445 2.2536857,0.7740699 0.0465,0.086881 0.04636,0.2779234 -5.12e-4,0.7256015 -0.129594,1.2376914 -0.479879,1.9239753 -1.13525,2.224201 -0.205333,0.094063 -0.384989,0.1187957 -0.9325437,0.1283803 -0.3730178,0.0066 -0.8308122,-0.011126 -1.0173211,-0.039234 z" />
							<path fill={theColor}
								d="M 16.999058,13.460043 V 8.914946 h 0.792764 0.792763 l 0.06175,0.2091303 c 0.08323,0.2818954 0.21597,1.0301417 0.309172,1.7427517 0.104197,0.796621 0.104197,4.389807 0,5.186428 -0.0932,0.71261 -0.225944,1.460859 -0.309172,1.742753 l -0.06175,0.20913 h -0.792763 -0.792764 z" />
							<path
								d="M6.179,22.685 C5.464,22.324,5,21.629,5,20.631v-3.372l-0.328-0.588C4.252,15.829,4,14.985,4,14.001L3.999,9.597c-0.001-0.552,0.447-1,0.999-1 c0.552-0.001,1,0.447,1,0.999L6,13.999c0,0.795,0.249,1.485,0.853,2.481C6.949,16.637,7,16.817,7,16.999v3.632 c0,0.191,0.003,0.229,0.081,0.269c0.129,0.065,0.367,0.099,0.737,0.099c0.603,0,0.792-0.189,0.908-0.388 C8.901,20.311,9,19.793,9,18.999c0-0.551,0.448-1,1-1h2c0.551,0,1,0.449,1,1v1.632c0,0.191,0.003,0.229,0.081,0.269 c0.129,0.065,0.367,0.099,0.737,0.099c0.603,0,0.792-0.189,0.908-0.388c0.175-0.3,0.273-0.819,0.273-1.612v-12c0-1.888-1.287-4-4-4 c-1.933,0-3.504,1.148-4.352,2.663c-0.271,0.481-0.88,0.653-1.361,0.384c-0.481-0.271-0.653-0.88-0.384-1.361 C6.039,2.658,8.217,0.999,11,0.999c4.023,0,6,3.192,6,6v12c0,0.853-0.087,1.835-0.548,2.623C15.929,22.516,15.032,23,13.819,23 c-0.375,0-1.047-0.013-1.64-0.315C11.464,22.324,11,21.629,11,20.631v-0.632h-0.061l-0.04,0.336 c-0.08,0.451-0.216,0.893-0.447,1.287C9.929,22.516,9.032,23,7.819,23C7.444,23,6.772,22.986,6.179,22.685z" />
							<path
								d="M3,7.999 c0-0.82,0.168-1.827,0.811-2.645C4.485,4.491,5.557,3.999,7,3.999c1.071,0,2.661,0.059,4.009,0.363 c0.668,0.151,1.361,0.38,1.911,0.753C13.496,5.507,14,6.131,14,6.999c0,1.128-0.176,2.349-0.78,3.319 c-0.651,1.044-1.728,1.681-3.22,1.681C7.632,11.999,3,10.871,3,7.999z M11.523,9.261C11.837,8.755,12,7.977,12,6.999 c0-0.027,0.001-0.089-0.204-0.229c-0.247-0.168-0.657-0.328-1.227-0.456C9.444,6.059,8.035,5.999,7,5.999 c-0.96,0-1.391,0.301-1.616,0.588C5.128,6.915,5,7.409,5,7.999c0,0.272,0.343,0.792,1.5,1.288C7.571,9.745,8.935,9.999,10,9.999 C10.833,9.999,11.255,9.691,11.523,9.261z" />
							<path
								d="M16,18.999c0-0.551,0.448-1,1-1 l1.536-0.005c0.02-0.061,0.039-0.127,0.06-0.203c0.075-0.279,0.145-0.643,0.207-1.08c0.123-0.873,0.199-1.988,0.199-3.212 c0-1.223-0.076-2.337-0.199-3.211c-0.061-0.437-0.132-0.801-0.207-1.08c-0.021-0.075-0.04-0.139-0.061-0.201L17,8.999 c-0.552,0-1-0.448-1-1c0-0.551,0.448-1,1-1h2c0.315,0,0.567,0.124,0.743,0.256c0.167,0.128,0.285,0.279,0.365,0.404 c0.193,0.296,0.324,0.676,0.42,1.032c0.101,0.381,0.187,0.829,0.256,1.32c0.137,0.981,0.217,2.191,0.217,3.488 c0,1.299-0.08,2.508-0.217,3.489c-0.069,0.491-0.155,0.939-0.256,1.32c-0.096,0.359-0.227,0.735-0.42,1.032 c-0.08,0.125-0.199,0.276-0.365,0.404c-0.176,0.132-0.428,0.255-0.743,0.255h-2C16.448,19.999,16,19.551,16,18.999z" />
						</svg>
					</div>
				</div>
			</div>
		</StyledMissionGuy>
	)
};

export default MissionGuy;


export const StyledMissionGuy = styled.div<{ spin: boolean }>`
	position: absolute;
	pointer-events: none;
	z-index: 1;

	width: 90px;
	height: 90px;
	
	animation: appear 1s;

	@keyframes appear {
		0% {
			transform: scale(0);
		}
		100% {
			transform: scale(1);
		}
	}

	.orbit {
		position: absolute;
		right: 0;
		bottom: 0;
		animation: rotate ${p => p.spin ? '3.0' : '20'}s infinite linear reverse;

		.bob {
			@keyframes bob {
				0% {
					transform: translateY(-5px) ;
				}
				100% {
					transform: translateY(5px) ;
				}
			}
			animation: bob ${p => p.spin ? '0.2' : '1.0'}s ease-in-out infinite alternate;
		}
	}
	.orbit-container {
		position: absolute;
		@keyframes rotate {
			0% {
				transform: translate(-50%, -50%) ;
			}
			100% {
				transform: translate(-50%, -50%) rotate(360deg);
			}
		}

		animation: rotate ${p => p.spin ? '3.0' : '20'}s infinite linear;
	}

	${p => p.spin ? `
		svg {
			@keyframes party {
				0%, 100% {
					transform: translateX(0px);
				}
				25% {
					transform: translateX(-5px);
				}
				75% {
					transform: translateX(5px);
				}
			}
			
			animation: party 1s ease-in-out infinite alternate;
		}
	` : ''}
	
`;
