import SchoolIcon from "@mui/icons-material/School";
import FolderIcon from "@mui/icons-material/Folder";
import ListIcon from "@mui/icons-material/List";
import PeopleIcon from "@mui/icons-material/People";
import SvgIcon from "@mui/material/SvgIcon";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import BoltIcon from '@mui/icons-material/Bolt';

export const niceColors = {
  darkPurple: "#3b2378",
  darkDarkPurple: "#21173a",
};

export const colors = {
  mainTextColor: "white",
  modalBackgroundColor: niceColors.darkPurple,
  modalChildBackgroundColor: niceColors.darkDarkPurple,
  modalOverlayColor: "rgba(0, 0, 0, 0.8)",
  asteroid: "#37E1DF",
  asteroid2: "#FC6AAF",
};

export const launchPanel = {
  color: niceColors.darkDarkPurple,
  height: "100px",
};

export const btnIcons: { [key: string]: typeof SvgIcon } = {
  class: SchoolIcon,
  folder: FolderIcon,
  set: ListIcon,
  user: PeopleIcon,
  rename: DriveFileRenameOutlineIcon,
  edit: ModeEditIcon,
  powerups: BoltIcon,
};

export const btnColors: { [key: string]: string } = {
  class: "#8ffaf1",
  folder: "#facf8f",
  set: "#fa8f8f",
  user: "#6afc6c",
  launch: "#6afc6c",
  home: "#e6ddfe",
  rename: "lightskyblue",
  delete: "rgb(255, 113, 113)",
  duplicate: "rgb(148, 215, 167)",

  presentation: "#fc7777",
  flashcards: "#ffc46b",
  gravity: "#6d6bff",
};
