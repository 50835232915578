// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyC3Rp8iriznEQ2Bjyv-SyadCLsVLKGmPNc",
    authDomain: "new-world-english.firebaseapp.com",
    projectId: "new-world-english",
    storageBucket: "new-world-english.appspot.com",
    messagingSenderId: "294530289615",
    appId: "1:294530289615:web:d6acf292e8c9aed9eb8cba",
    measurementId: "G-W935LLH51X",
    databaseURL: "https://new-world-english-default-rtdb.asia-southeast1.firebasedatabase.app",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export {
    auth,
}
