import styled from "styled-components/macro";
import { useEffect, useRef } from "react";
interface GameProgressItemProps {
  value: string;
  color: string;
  className?: string;
  children: React.ReactNode;
}

const GameProgressItem = (props: GameProgressItemProps) => {
  const { value, children } = props;

  const valueElem = useRef<HTMLDivElement>(null);
  const prevValue = useRef(0);
  useEffect(() => {
    const [current, prev] = [Number(value), prevValue.current];
    if (current > prev)
      valueElem.current!.getAnimations().forEach((x) => x.play());
    prevValue.current = current;
  }, [value]);

  return (
    <StyledGameProgressItem {...props}>
      <div className="name">{children}</div>
      <div className="value" ref={valueElem}>
        {value}
      </div>
    </StyledGameProgressItem>
  );
};

export default GameProgressItem;

const StyledGameProgressItem = styled.div<GameProgressItemProps>`
  .name {
    color: rgba(255, 255, 255, 0.8);
  }

  .value {
    font-size: 2rem;
    color: ${(p) => p.color};
    text-transform: initial;
    animation: appear 0.4s forwards;
  }

  @keyframes appear {
    0% {
      scale: 1;
    }
    20% {
      scale: 1.1;
    }
    100% {
      scale: 1;
    }
  }
`;
