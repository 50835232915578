import styled from "styled-components/macro";
import { colors } from "../shared-styles";
interface IProps extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
  color?: string;
  children: React.ReactNode;
}

const ModalChild = ({ children, color, className }: IProps) => {
  return (
    <StyledModalChild {...{ color }} className={`modal-child ${className ?? ''}`}>
      {children}
    </StyledModalChild>
  );
};

export default ModalChild;

const { modalChildBackgroundColor } = colors;
const StyledModalChild = styled.div<IProps>`
  background: ${(p) => p.color ?? modalChildBackgroundColor};
  border-radius: 10px;
  padding: 20px;
`;
