import styled from "styled-components/macro";
import { useNavigate, useParams } from "react-router-dom";
import { MediumButton } from "./TheGame/Button";
import { useEffect, useState } from "react";
import { getDatabase, ref, get } from "firebase/database";
import AppHeader from "./AppHeader";
import { btnIcons, btnColors } from "./shared-styles";
import HomeHeader from "./HomeHeader";
import LaunchPanel from "./LaunchPanel";
import ReportIcon from "@mui/icons-material/Report";
import SlideDown from "./SlideDown";
import starryBackground from "./TheGame/assets/imgs/starry_background.svg";

const db = getDatabase();

const HomeClass = () => {
  const { id: classId } = useParams();

  const navigate = useNavigate();

  const [folders, setFolders] = useState<string[] | null>(null);
  const [sets, setSets] = useState<string[] | null>(null);
  const [selectedSet, setSelectedSet] = useState<string | undefined>(undefined);
  const [hasLaunched, setHasLaunched] = useState(false);

  useEffect(() => {
    const dbRef = ref(db, `classDefs/${classId}`);
    get(dbRef).then((snapshot) => {
      const val = snapshot.val();
      setFolders(Object.keys(val?.folders ?? {}));
      setSets(Object.keys(val?.sets ?? {}));
    });
  }, [classId]);

  const folderBtn = (folderId: string, i: number) => (
    <SlideDown key={i} delay={Math.random() * 0.15} duration={0.25}>
      <MediumButton
        className="folder-btn"
        key={i}
        onClick={() => navigate(`folder/${folderId}`)}
        buttonText={folderId}
        icon={btnIcons.folder}
        color={btnColors.folder}
      ></MediumButton>
    </SlideDown>
  );

  const setBtn = (setId: string, i: number) => (
    <SlideDown key={i} delay={Math.random() * 0.15} duration={0.25}>
      <MediumButton
        className="set-btn"
        key={i}
        onClick={() => setSelectedSet((s) => (s === setId ? undefined : setId))}
        buttonText={setId}
        icon={btnIcons.set}
        color={btnColors.set}
        selected={setId === selectedSet}
      ></MediumButton>
    </SlideDown>
  );

  return (
    <StyledHomeClass>
      <AppHeader>
        <div className="home-class">
          <HomeHeader
            backTo="/home"
            isReady={!!selectedSet}
            hasLaunched={hasLaunched}
          />
          {sets?.length === 0 && folders?.length === 0 && (
            <div className="no-folders-sets">
              <ReportIcon />
              <span>NO FOLDERS / SETS</span>
            </div>
          )}
          <div className="home-class-children">
            {!!sets?.length && sets.map((set, i) => setBtn(set, i))}
          </div>
          <div className="home-class-children">
            {!!folders?.length &&
              folders.map((folder, i) => folderBtn(folder, i))}
          </div>
        </div>
        <LaunchPanel
          readyTypes={["class", ...(selectedSet ? ["folder", "set"] : [])]}
          handleLaunch={() => {
            setHasLaunched(true);
            return new Promise((resolve) => setTimeout(resolve, 500));
          }}
          setId={selectedSet}
        />
      </AppHeader>
    </StyledHomeClass>
  );
};

export default HomeClass;

const StyledHomeClass = styled.div`
  background-image: url(${starryBackground});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  color: white;

  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;

  .home-class {
    flex: 1;
    display: flex;
    flex-direction: column;

    .home-class-children {
      justify-content: center;
      display: flex;
      flex-wrap: wrap;
      .button-container {
        margin-bottom: 20px;
      }
    }
    .home-class-children:last-child {
      margin-bottom: 100px;
    }
  }

  .no-folders-sets {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;
