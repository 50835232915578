import styled from "styled-components/macro";

const MissionClouds = ({ color = '#f87e3c', key }: { color: string, key: number }) => {
  const dx = (key % 10) * 10;
  return (
    <StyledMissionClouds className="clouds">
      <svg viewBox="0 0 200 200" width="100%" height="100%">
        {/* Clouds */}
        {/* Cloud 1  */}
        <rect className="r" x={50 + dx % 40} y="20" width="40" height="15" rx="8" fill={color} />
        <rect x={70 - dx % 30} y="30" width="50" height="15" rx="8" fill={color} />
        <rect x="30" y="40" width="70" height="15" rx="8" fill={color} />

        {/* Cloud 2 */}
        <rect x="15" y="120" width="30" height="15" rx="8" fill={color} />
        <rect x="10" y="130" width="50" height="15" rx="8" fill={color} />
        <rect x="35" y="140" width="40" height="15" rx="8" fill={color} />
        <rect x={45 - dx % 50} y="150" width="30" height="15" rx="8" fill={color} />

        {/* Cloud 3 */}
        <rect x={130 - dx % 30} y="45" width="50" height="15" rx="8" fill={color} />
        <rect x="110" y="55" width="70" height="15" rx="8" fill={color} />
        <rect x="140" y="65" width="40" height="15" rx="8" fill={color} />

        {/* Cloud 4 */}
        <rect x="140" y="120" width="30" height="15" rx="8" fill={color} />
        <rect x="140" y="130" width="50" height="15" rx="8" fill={color} />
        <rect x={120 + dx % 40} y="140" width="40" height="15" rx="8" fill={color} />

        {/* Cloud 5 */}
        <rect x="80" y="160" width="40" height="15" rx="8" fill={color} />
        <rect x={85 - dx % 30} y="170" width="60" height="15" rx="8" fill={color} />
        <rect x="90" y="180" width="50" height="15" rx="8" fill={color} />
      </svg>
    </StyledMissionClouds>
  )
};

export default MissionClouds;


export const StyledMissionClouds = styled.div`
position: absolute;
rect {
  transition: transform 0.3s ease;
}

@keyframes wobble {
  0% {
    transform: translateX(0);
  }
  20% {
    transform: translateX(-7px);
  }
  80% {
    transform: translateX(7px);
  }
  100% {
    transform: translateX(0);
  }
}

rect {
  animation: wobble 4s linear forwards infinite;
  animation-play-state: paused;
  &:nth-child(odd) {
      animation-direction: reverse;
  }
}

&:hover {
  rect {
    animation-play-state: running;
  }
}
  `;