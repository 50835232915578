import styled from "styled-components/macro";
import { useRef } from "react";
import { SmallButton } from "../../TheGame/Button";
import ImageIcon from "@mui/icons-material/Image";
import { FilesUploader } from "./FilesUploader";
import { useParams } from "react-router-dom";

interface Props {
  onComplete: (files: Record<string, File>) => void;
}

function BulkUploadButton({ onComplete }: Props) {
  const { id: setId } = useParams();
  const inputEl = useRef<HTMLInputElement | null>(null);

  return (
    <StyledBulkUploadButton>
      <SmallButton
        icon={ImageIcon}
        onClick={() => inputEl.current!.click()}
        buttonText="Upload Images"
        color="orange"
        className="bulk-upload-btn"
      ></SmallButton>
      <FilesUploader
        setId={setId!}
        inputRef={inputEl}
        multiple={true}
        onComplete={onComplete}
      ></FilesUploader>
    </StyledBulkUploadButton>
  );
}

export default BulkUploadButton;

const StyledBulkUploadButton = styled.div``;
