import { useState, useLayoutEffect, useMemo } from "react";
import styled from "styled-components/macro";
import { IActivePrompt } from "./TheGame";
import redAsteroid from "./assets/imgs/asteroid_red.svg";
import greenAsteroid from "./assets/imgs/asteroid_green.svg";
import goldAsteroid from "./assets/imgs/asteroid_gold.svg";
import asteroidExplosion from "./assets/imgs/asteroid_explosion.gif";
import { colors } from "../shared-styles";
import { useSearchParams } from "react-router-dom";

interface ActivePromptProps extends IActivePrompt {
  isRunning: boolean;
  onExpired: () => void;
}

function ActivePrompt({
  index,
  prompt: { data, isLastChance, isPowerup },
  isRunning,
  isExpired,
  isCompleted,
  expiry,
  onExpired,
}: ActivePromptProps) {
  const [searchParams] = useSearchParams();
  const debug = searchParams.get("debug") === "true";
  const [timeRemaining, setTimeRemaining] = useState(expiry);
  const [chinese, imgSrc] = [data.c, data.i];

  const progress = useMemo(
    () => (isExpired ? 100 : 100 - (100 * timeRemaining) / expiry),
    [expiry, timeRemaining, isExpired]
  );

  // speed up asteroid at start to make the prompt text visible earlier
  const initialBoost = useMemo(() => {
    if (isExpired) return 0;
    const timeElapsed = expiry - timeRemaining;
    const duration = 300;
    const maxBoost = 80;
    return (
      maxBoost *
      (timeElapsed < duration
        ? Math.sqrt(timeElapsed / duration)
        : 1 - (1 * (timeElapsed - duration)) / (expiry - duration))
    );
  }, [expiry, isExpired, timeRemaining]);

  useLayoutEffect(() => {
    if (isRunning && !isCompleted && !isExpired) {
      if (timeRemaining <= 0) {
        onExpired();
      } else {
        let timerId!: number;
        let time = Date.now();
        const cb = () => {
          const newTime = Date.now();
          const elapsed = newTime - time;
          time = newTime;
          setTimeRemaining((t) => t - elapsed);
          timerId = window.requestAnimationFrame(cb);
        };

        timerId = window.requestAnimationFrame(cb);
        return () => window.cancelAnimationFrame(timerId);
      }
    }
  }, [isRunning, isCompleted, onExpired, isExpired, timeRemaining]);

  const [xAlign] = useState(Math.floor(Math.random() * 100));

  const styleProps = {
    index,
    progress,
    initialBoost,
    isLastChance,
    xAlign,
    isRunning,
    isCompleted,
    isExpired,
  };

  const shouldExplode = isExpired || isCompleted;

  const asteroidType = shouldExplode
    ? "explosion"
    : isPowerup
    ? "powerup"
    : isLastChance
    ? "last-chance"
    : "normal";

  const backgroundSrc = shouldExplode
    ? asteroidExplosion
    : isPowerup
    ? goldAsteroid
    : isLastChance
    ? redAsteroid
    : greenAsteroid;

  // const getSrc = (img: string) => {
  //   try {
  //     return require(`./assets/vocab/${img}`);
  //   } catch (ex) {
  //     return null;
  //   }
  // };

  return (
    <StyledActivePrompt {...styleProps} className="active-prompt">
      {debug && (
        <div className="debug">{`${(timeRemaining / 1000).toFixed(1)}s`}</div>
      )}
      <img
        src={backgroundSrc}
        alt="asteroid"
        className="background"
        data-type={asteroidType}
      />
      {isPowerup && isCompleted && <div className="powerup-text">+500</div>}
      <div className="active-prompt-content">
        {imgSrc && <img className="prompt-img" src={imgSrc} alt="img"></img>}
        <div className="chinese">{chinese}</div>
      </div>
    </StyledActivePrompt>
  );
}

export default ActivePrompt;

interface StyledActivePromptProps {
  index: number;
  progress: number;
  initialBoost: number;
  isLastChance: boolean;
  xAlign: number;
  isRunning: boolean;
  isCompleted: boolean;
  isExpired: boolean;
}

const StyledActivePrompt = styled.div.attrs<StyledActivePromptProps>(
  ({ xAlign, progress, initialBoost, index }) => ({
    style: {
      transform: `translateX(${-xAlign}%) translateY(${-100 + initialBoost}%)`,
      top: `${progress}%`,
      left: `${xAlign}%`,
      zIndex: `${-index}`,
    },
  })
)<StyledActivePromptProps>`
  position: absolute;
  color: ${colors.modalBackgroundColor};
  font-size: 1.5rem;
  font-weight: bold;
  border-radius: 50%;
  display: flex;
  width: fit-content;
  aspect-ratio: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;

  .debug {
    position: absolute;
    bottom: 0px;
    color: white;
  }

  .powerup-text {
    @keyframes powerup-text {
      0% {
        transform: scale(0);
      }
      30% {
        transform: scale(1.05);
      }
      40% {
        transform: scale(1);
      }
      65% {
        opacity: 1;
      }
      100% {
        opacity: 0;
      }
    }
    font-size: 3rem;
    position: absolute;
    color: white;
    animation: powerup-text 1s ease;
  }

  .background {
    position: absolute;
    z-index: -1;
    height: 100%;
    width: 100%;

    &[data-type="explode"] {
      @keyframes explode {
        0% {
        }
        100% {
          transform: scale(2);
          opacity: 0;
        }
      }
      animation: explode 1s;
    }

    &[data-type="powerup"] {
      filter: brightness(1.25);
    }
  }

  .active-prompt-content {
    display: flex;
    visibility: ${(props) =>
      props.isCompleted || props.isExpired ? "hidden" : "visible"};
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: ${(props) => (props.isRunning ? 1 : 0)};
    transition: opacity 0.15s;
    min-width: 10vh;

    .chinese {
      width: max-content;
      padding-left: 15px;
      padding-right: 15px;
    }

    .prompt-img {
      height: 18vh;
      margin: 3vh 6vh;
    }
  }
`;
