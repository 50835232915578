import styled from "styled-components/macro";
import pauseIcon from "./assets/imgs/pause_icon.svg";

interface PauseButtonProps {
  onClick: VoidFunction;
  disabled: boolean;
}

const PauseButton = ({ onClick, disabled }: PauseButtonProps) => {
  return (
    <StyledPauseButton>
      <button className="pause" onClick={onClick} disabled={disabled}>
        <img src={pauseIcon} alt="pause" />
      </button>
    </StyledPauseButton>
  );
};

export default PauseButton;

const StyledPauseButton = styled.div`
  transform: rotate(90deg);
`;
