import { SmallButton } from "./TheGame/Button";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";

interface Props {
  to?: string | null;
}

function BackButton({ to }: Props) {
  const navigate = useNavigate();
  return (
    <SmallButton
      className="back-button"
      onClick={() => (to ? navigate(to) : navigate(-1))}
      buttonText={<ArrowBackIcon />}
      width={50}
    ></SmallButton>
  );
}

export default BackButton;
