import { useState } from "react";
import Modal from "./Modal";
import styled from "styled-components/macro";
import Button from "./Button";
import OrbitingAsteroids from "./OrbitingAsteroids";
import asteroidsExplainer from "./assets/imgs/asteroids_explainer.svg";
import ModalChild from "./ModalChild";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import { btnColors, colors } from "../shared-styles";
import { SmallButton } from "./Button";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";

interface IProps {
  onStart: (difficulty: number, powerups: boolean) => void;
  canStart: boolean;
  hasPowerups: boolean;
}

const difficultyColors = [colors.asteroid, "orange", colors.asteroid2];

function StartGame({ onStart, canStart, hasPowerups }: IProps) {
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [difficulty, setDifficulty] = useState<number>(1);
  const [powerups, setPowerups] = useState(false);

  const steps: JSX.Element[] = [
    <>
      <div className="text-container">
        <h1>GRAVITY</h1>
        <h3>PROTECT YOUR PLANET FROM INCOMING ASTEROIDS!</h3>
      </div>
      <div className="orbiting-asteroids-container">
        <OrbitingAsteroids />
      </div>
      <Button onClick={incrementStep} buttonText="GET STARTED" />
    </>,
    <>
      <div className="text-container">
        <h1>options</h1>
      </div>
      <div className="options-container">
        <ModalChild>
          <div className="options">
            {["easy", "medium", "hard"].map((diff, i) => {
              return (
                <Button
                  width={200}
                  className="option"
                  key={i}
                  onClick={() => setDifficulty(i)}
                  selected={difficulty === i}
                  buttonText={diff.toUpperCase()}
                  color={difficultyColors[i]}
                ></Button>
              );
            })}

            {hasPowerups && (
              <div className="option-powerups">
                <SmallButton
                  buttonText="POWERUPS"
                  selected={powerups}
                  color="gold"
                  icon={powerups ? CheckBoxIcon : CheckBoxOutlineBlankIcon}
                  onClick={() => {
                    setPowerups((p) => !p);
                  }}
                />
              </div>
            )}
          </div>
        </ModalChild>
      </div>
      <Button onClick={incrementStep} width={200} buttonText="LET'S GO!" />
    </>,
    <>
      <div className="text-container">
        <h1>WARNING!</h1>
        <h3>
          IF YOU MISS A WORD TWICE, RED ASTEROIDS WILL DESTROY YOUR PLANET!
        </h3>
      </div>
      <div className="explainer-container">
        <img src={asteroidsExplainer} alt="" className="asteroids-explainer" />
      </div>
      <Button
        disabled={!canStart}
        width={200}
        icon={RocketLaunchIcon}
        color={btnColors.launch}
        onClick={() => onStart(difficulty, powerups)}
        buttonText="LAUNCH!"
      />
    </>,
  ];

  function incrementStep() {
    setCurrentStep((currentStep) => currentStep + 1);
  }

  return (
    <Modal>
      <StyledStartGame>
        <div className="main-container">{steps[currentStep - 1]}</div>
      </StyledStartGame>
    </Modal>
  );
}

export default StartGame;

const StyledStartGame = styled.div`
  width: 100%;

  .main-container {
    width: 100%;
    padding: 10px 20px 25px 20px;
    height: 500px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  h1 {
    font-size: 3rem;
    text-align: left;
    margin: 0;
  }

  h3 {
    margin: 0;
    text-align: left;
  }

  .orbiting-asteroids-container {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .explainer-container {
    width: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .asteroids-explainer {
    height: 300px;
    aspect-ratio: 1;
  }

  .options-container {
    display: flex;
    justify-content: center;

    .modal-child {
      padding-left: 30px;
      padding-right: 30px;
    }
  }

  .options {
    display: flex;
    flex-direction: column;
    align-items: center;

    .option {
      margin-top: 10px;
    }

    .option-powerups {
      margin-top: 20px;
      align-self: flex-end;
    }
  }
`;
