import { ChangeEvent } from "react";
import { IActivePrompt } from "./TheGame";
import Modal from "./Modal";
import GameProgressItem from "./GameProgressItem";
import styled from "styled-components/macro";
import ModalChild from "./ModalChild";
import { trimVal } from "../utils";
import ActivePrompt from './ActivePrompt';

interface IExpiredPromptProps {
  prompt: IActivePrompt;
  onAnswered: VoidFunction;
}

function ExpiredPrompt({ prompt, onAnswered }: IExpiredPromptProps) {
  function onChange(e: ChangeEvent<HTMLInputElement>) {
    const value = e.currentTarget.value.toLowerCase();
    if (trimVal(value) === trimVal(prompt.prompt.data.e).toLowerCase()) onAnswered();
  }

  return (
    <StyledExpiredPrompt>
      <Modal>
        <GameProgressItem
          className="prompt"
          color="white"
          value={prompt.prompt.data.c}
        >
          prompt
        </GameProgressItem>
        <ModalChild>
          <ActivePrompt isRunning={true} onExpired={() => { }} {...prompt} isExpired={false} />
        </ModalChild>
        <GameProgressItem
          className="answer"
          color="white"
          value={prompt.prompt.data.e}
        >
          correct answer
        </GameProgressItem>
        <input
          autoComplete="false"
          aria-autocomplete="none"
          autoFocus
          id="expired-input"
          type="text"
          title="expired input"
          placeholder="COPY ANSWER"
          onChange={onChange}
        />
      </Modal>
    </StyledExpiredPrompt>
  );
}

export default ExpiredPrompt;

const StyledExpiredPrompt = styled.div`
  .modal {
    justify-content: space-between;
  }

  .answer {
    padding-bottom: 20px;
  }

  .prompt .value {
    font-size: 3rem;
  }

  .answer .value {
    font-size: 2rem;
  }

  .modal-child {
    margin-block: 10px;
  }

  .active-prompt {
    position: sticky;
    transform: none !important;
    z-index: 0 !important;
  }
`;
