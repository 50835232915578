import AddIcon from "@mui/icons-material/Add";
import styled from "styled-components/macro";
import { useState } from "react";
import { nanoid } from "nanoid";
import { ItemData } from "./EditSet";
import { SmallButton } from "../../TheGame/Button";
import { btnColors, launchPanel } from "../../shared-styles";

interface AddItemProps {
  onAdd: (data: ItemData) => void;
}

export function AddSetItem({ onAdd }: AddItemProps) {
  const [addItem, setAddItem] = useState<ItemData>({
    e: "",
    c: "",
    id: nanoid(12),
    t: +new Date(),
  });
  const { e, c } = addItem;

  function onChange(newData: Partial<ItemData>) {
    setAddItem((oldData) => ({
      ...oldData,
      ...newData,
    }));
  }

  const isValid = !!(e && c);
  return (
    <StyledAddSetItem className="add-set-item" {...{ isAdd: !!onAdd }}>
      <SmallButton
        color={btnColors.launch}
        height={60}
        disabled={!isValid}
        className="add-item"
        icon={AddIcon}
        buttonText="Add Item"
        onClick={() => {
          onAdd(addItem);
          setAddItem({ e: "", c: "", id: nanoid(12), t: +new Date() });
        }}
      ></SmallButton>
      <div className="add-inputs">
        <input
          type="text"
          id="item-chinese"
          placeholder="輸入中文"
          value={c}
          onChange={({ target }) => {
            onChange({ c: target.value });
          }}
        />
        <input
          type="text"
          id="item-english"
          placeholder="Enter English"
          value={e}
          onChange={({ target }) => {
            onChange({ e: target.value });
          }}
        />
      </div>
    </StyledAddSetItem>
  );
}

const StyledAddSetItem = styled.div`
  display: flex;
  align-items: center;
  /* background-color: ${launchPanel.color}; */
  /* border-radius: 5px; */

  .add-inputs {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: start;
    margin: 10px;
  }
  .add-inputs input {
    width: 100%;
  }
  .btn-add {
    width: 100%;
    height: 100%;
  }

  .btn-add:hover:not(.disabled) {
    scale: 1.05;
    opacity: 1;
  }
`;
