import styled from "styled-components/macro";
import { useParams } from "react-router-dom";
import { SmallButton } from "../TheGame/Button";
import AdminGroup from "./AdminGroup";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { get, getDatabase, ref, update } from "firebase/database";
import { useEffect, useState, useMemo, useCallback } from "react";
import { btnColors, btnIcons } from "../shared-styles";
import Loading from "../Loading";
import EditHeader from "./EditHeader";
import AppHeader from "../AppHeader";
import { deleteClass, renameClass } from "./adminUtils";
import ItemActions from "./ItemActions";
import SearchInput from "../SearchInput";
import HighscoresButton from "./Set/HighscoresButton";
import SelectOrEditButton from "./SelectOrEditButton";

const db = getDatabase();
// class folders, class sets, class users'
export interface IClassDef {
  folders: { [key: string]: boolean | null };
  sets: { [key: string]: boolean | null };
}
async function fetchClassDef(classId: string) {
  const dbRef = ref(db, `classDefs/${classId}`);
  return get(dbRef).then((ss) => ss.val() as IClassDef | undefined);
}
async function fetchClassUsers(classId: string) {
  const dbRef = ref(db, `classUsers/${classId}`);
  return get(dbRef).then((ss) => ss.val() as { [key: string]: boolean | null });
}

const EditClass = () => {
  const [search, setSearch] = useState("");
  const { id: classId } = useParams();
  const [loadingParts, setLoadingParts] = useState({
    classDef: true,
    classUsers: true,
    folders: false,
    sets: false,
    users: false,
  });
  const [folders, setFolders] = useState<{ [key: string]: boolean | null }>({});
  const [sets, setSets] = useState<{ [key: string]: boolean | null }>({});
  const [users, setUsers] = useState<{ [key: string]: boolean | null }>({});
  const [userClassesUpdates, setUserClassesUpdates] = useState<{
    [key: string]: boolean | null;
  }>({});
  const [hasChanged, setHasChanged] = useState(false);

  useEffect(() => {
    if (!classId) console.error("no id");
    fetchClassDef(classId!).then((classDef) => {
      setFolders(classDef?.folders ?? {});
      setSets(classDef?.sets ?? {});
      setLoadingParts((parts) => ({
        ...parts,
        classDef: false,
      }));
    });
    fetchClassUsers(classId!).then((users) => {
      setUsers(users ?? {});
      setLoadingParts((parts) => ({
        ...parts,
        classUsers: false,
      }));
    });
  }, [classId]);

  const itemBuilder = (
    type: string,
    id: string,
    selected: boolean,
    onClick: VoidFunction
  ) => {
    return (
      <SmallButton
        key={id}
        buttonText={id}
        onClick={onClick}
        icon={selected ? CheckBoxIcon : CheckBoxOutlineBlankIcon}
        color={btnColors[type]}
        selected={selected}
      ></SmallButton>
    );
  };

  const onUserClick = useCallback(
    (id: string) => {
      setHasChanged(true);
      const newValue = !users[id] || null;
      setUserClassesUpdates((updates) => ({
        ...updates,
        [`userClasses/${id}/${classId}`]: newValue,
      }));
      setUsers((users) => ({
        ...users,
        [id]: newValue,
      }));
    },
    [classId, users]
  );

  const groups = useMemo(
    () => ({
      folders: {
        entityName: "folder",
        itemBuilder: (id: string) =>
          <SelectOrEditButton
            key={id}
            type={"folder"}
            id={id}
            onClick={() => {
              setHasChanged(true);
              setFolders((folders) => ({
                ...folders,
                [id]: !folders[id] || null,
              }));
            }}
            selected={!!folders?.[id]}
          ></SelectOrEditButton>,
        onLoaded: () =>
          setLoadingParts((parts) => ({ ...parts, folders: false })),
        idFilter: search,
        grouping: (id: string) => (!!folders?.[id] ? "in" : "out"),
      },
      sets: {
        entityName: "set",
        itemBuilder: (id: string) =>
          <SelectOrEditButton
            key={id}
            type={"set"}
            id={id}
            onClick={() => {
              setHasChanged(true);
              setSets((sets) => ({
                ...sets,
                [id]: !sets[id] || null,
              }));
            }}
            selected={!!sets?.[id]}
          ></SelectOrEditButton>,
        onLoaded: () => setLoadingParts((parts) => ({ ...parts, sets: false })),
        idFilter: search,
        grouping: (id: string) => (!!sets?.[id] ? "in" : "out"),
      },
      users: {
        entityName: "user",
        onItemAdd: (id: string) => {
          onUserClick(id);
        },
        itemBuilder: (id: string) =>
          itemBuilder("user", id, !!users?.[id], () => onUserClick(id)),
        onLoaded: () =>
          setLoadingParts((parts) => ({ ...parts, users: false })),
        idFilter: search,
        grouping: (id: string) => (!!users?.[id] ? "in" : "out"),
      },
    }),
    [folders, onUserClick, search, sets, users]
  );

  async function save() {
    const rootRef = ref(db);
    const updateObj = {
      [`classDefs/${classId!}`]: { folders, sets },
      [`classUsers/${classId!}`]: users,
      ...userClassesUpdates,
    };
    update(rootRef, updateObj).then(() => {
      setHasChanged(false);
    });
  }

  const isLoading = Object.values(loadingParts).some((v) => v);

  const highscoresButton = (
    <HighscoresButton folders={Object.keys(folders)} sets={Object.keys(sets)} />
  );

  return (
    <StyledAdmin>
      <AppHeader>
        <Loading isLoading={isLoading}>
          <EditHeader
            save={save}
            saveDisabled={!hasChanged}
            icon={btnIcons.class}
            title={classId!}
            backTo={"/admin"}
          ></EditHeader>
          <ItemActions
            id={classId!}
            itemType="class"
            rename={(from, to) =>
              renameClass(from, to).then(() => setHasChanged(false))
            }
            deleteItem={deleteClass}
          >
            {highscoresButton}
          </ItemActions>
          <SearchInput onSearchValue={setSearch} />
          <AdminGroup {...groups.folders!}></AdminGroup>
          <AdminGroup {...groups.sets!}></AdminGroup>
          <AdminGroup canAdd {...groups.users!}></AdminGroup>
        </Loading>
      </AppHeader>
    </StyledAdmin>
  );
};

export default EditClass;

const StyledAdmin = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 400px;
  margin: auto;

  .groups.loading {
    visibility: hidden;
  }

  .item-actions {
    margin-bottom: 15px;
    display: flex;
    justify-content: center;
  }
`;
