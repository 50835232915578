import styled from "styled-components/macro";
import { useState } from "react";
import Modal from "../../TheGame/Modal";
import ModalChild from "../../TheGame/ModalChild";
import { uploadFiles } from "../adminUtils";

interface Props {
  inputRef: React.MutableRefObject<HTMLInputElement | null>;
  multiple: boolean;
  setId?: string;
  id?: string;
  onComplete?: (files: Record<string, File>) => void;
}

export function FilesUploader({
  inputRef,
  id,
  setId,
  multiple,
  onComplete,
}: Props) {
  const [filesUploading, setFilesUploading] = useState<Record<string, number>>(
    {}
  );

  async function uploadFileList(fileList: FileList) {
    const files = [...fileList];
    const result = await uploadFiles({
      files,
      onProgressChanged: (uploading) => setFilesUploading(uploading),
      setId,
      ids: id ? [id] : [],
    });
    inputRef.current!.value = "";
    onComplete?.(result);
  }

  return (
    <StyledFilesUploader>
      <input
        ref={inputRef}
        multiple={multiple}
        type="file"
        id="image-input"
        name="image"
        title="image"
        accept="image/png, image/jpeg"
        onChange={({ target }) => target.files && uploadFileList(target.files)}
      />
      {Object.keys(filesUploading).length > 0 && (
        <Modal>
          <h1 className="files-upload-title">Uploading...</h1>
          <ModalChild>
            <div className="files-uploading">
              {Object.entries(filesUploading).map(([name, progress]) => (
                <div className="file-uploading" key={name}>
                  <div className="file-name">{name}</div>
                  <div className="file-progress">{progress}</div>
                </div>
              ))}
            </div>
          </ModalChild>
        </Modal>
      )}
    </StyledFilesUploader>
  );
}

const StyledFilesUploader = styled.div`
  display: flex;

  #image-input {
    display: none;
  }

  .file-uploading {
    display: flex;
    justify-content: space-between;

    .file-progress {
      margin-left: 30px;
    }
  }
`;
