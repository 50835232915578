import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Login from "./Login";
import { useAuthState } from "react-firebase-hooks/auth";
import Home from "./Home";
import Admin from "./Admin/Admin";
import { getAuth } from "firebase/auth";
import Loading from "./Loading";
import EditSet from "./Admin/Set/EditSet";
import EditClass from "./Admin/EditClass";
import EditFolder from "./Admin/EditFolder";
import EditUser from "./Admin/EditUser";
import Logo from "./Logo";
import HomeClass from "./HomeClass";
import HomeFolder from "./HomeFolder";
import TheGame from "./TheGame/TheGame";
import useIsTeacher from "./useIsTeacher";
import EditPowerups from "./Admin/Set/EditPowerups";
import ClassSetHighscores from "./Admin/Set/ClassSetHighscores";
import useIsAdmin from "./useIsAdmin";
import MissionSelect from "./MissionSelect";
import TheFlashcards from "./TheFlashcards/TheFlashcards";

const auth = getAuth();

function App() {
  const [user, loading] = useAuthState(auth);
  const isTeacher = useIsTeacher(auth);
  const isAdmin = useIsAdmin(auth);

  if (loading) {
    return <Logo />;
  }
  return (
    <Router>
      <div className="App">
        <Routes>
          {user ? (
            <>
              <Route path="home" element={<Home />} />
              <Route path="home/class/:id" element={<HomeClass />} />
              <Route
                path="home/class/:classId/folder/:folderId"
                element={<HomeFolder />}
              />
              {isTeacher !== false && (
                <>
                  {!isTeacher && <Route path="admin/*" element={<Loading />} />}
                  <Route path="admin" element={<Admin />} />
                  {isAdmin && (
                    <Route path="admin/powerups" element={<EditPowerups />} />
                  )}
                  <Route path="admin/class/:id" element={<EditClass />} />
                  <Route
                    path="admin/class/:classId/highscores/:setId"
                    element={<ClassSetHighscores />}
                  />
                  {isAdmin && (
                    <Route path="admin/folder/:id" element={<EditFolder />} />
                  )}
                  {isAdmin && (
                    <Route path="admin/set/:id" element={<EditSet />} />
                  )}
                  {isAdmin && (
                    <Route path="admin/user/:id" element={<EditUser />} />
                  )}
                </>
              )}
              <Route path="gravity/:setId" element={<TheGame />} />
              <Route
                path="mission-select/:setId/flashcards"
                element={<TheFlashcards />}
              />

              <Route
                path="mission-select/:setId"
                element={<MissionSelect />}
              />

              {/* old style mission select urls */}
              <Route
                path="home/class/:classId/folder/:folderId/mission-select/:setId/flashcards"
                element={<TheFlashcards />}
              />
              <Route
                path="home/class/:classId/mission-select/:setId/flashcards"
                element={<TheFlashcards />}
              />
              <Route
                path="home/class/:classId/folder/:folderId/mission-select/:setId"
                element={<MissionSelect />}
              />
              <Route
                path="home/class/:classId/mission-select/:setId"
                element={<MissionSelect />}
              />

              <Route path="*" element={<Navigate to="home" replace />}></Route>
            </>
          ) : (
            <>
              <Route path="login" element={<Login />} />
              <Route path="demo" element={<TheGame demo={true} />} />
              <Route
                path="mission-select/:setId"
                element={<MissionSelect />}
              />

              {/* old style mission select urls */}
              <Route
                path="home/class/:classId/folder/:folderId/mission-select/:setId"
                element={<MissionSelect />}
              />
              <Route
                path="home/class/:classId/mission-select/:setId"
                element={<MissionSelect />}
              />

              <Route path="*" element={<Navigate to="login" replace />}></Route>
            </>
          )}
        </Routes>
      </div>
    </Router>
  );
}

export default App;
